
/* ----------popup css------ */
.team_popup_custom.modal-lg {
    @media (min-width: 1200px) and (max-width: 1400px) {
        max-width: 500px;
    }
 

    .modal-content {
        @include border-radius(12.5px);
        border: none;
    }

    .modal-body {
        padding: 30px;

        @media (min-width: 1200px) and (max-width: 1400px) {
            padding: 50px
        }


        &::before {
            background: url(../img/modal_img.png) no-repeat;
            background-size: 140%;
            height: 100%;
            background-position: center;
            position: absolute;
            bottom: 0px;
            content: '';
            left: 0;
            z-index: 0;
            width: 36%;

        }

        .btn-close {
            position: $absolute;
            right: -15px;
            top: -15px;
            width: 3rem;
            height: 3rem;
            padding: 0;
            opacity: 1;
            background: $white-color;
            @include border-radius(50%);
            &:focus {
                border: none;
                box-shadow: none;
            }
        }


        .team_memeber-img {
            background-color: var(--lighter-pink);
            border-radius: 50%;
            overflow: hidden;
            min-width: 230px;
            max-width: 230px;
            min-height: 230px;
            max-height: 230px;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                padding-top: 30px;
            }
        }

        .team_member_info {
            form {
                label {
                    color: var(--sky-blue-dark100);
                    font-size: 1.3rem;
                    line-height: 1.3;
                }

                input {
                    background-color: var(--light-white);
                    border-radius: 12.5px;
                    min-height: 51px;
                    border: none;
                    font-size: 1.25rem;
                    color: var(--base-color);
                }
            }
        }
    }

    .team_info_row {
        display: grid;
        grid-template-columns: 250px auto;
        gap: 1rem;
        position: relative;
        align-items: center;

    }
}

.coming_soon_popup.modal-lg .team_info_row {
    display: flex;
    flex-flow: column;
    gap: 1rem;
    position: relative;
    align-items: center;

    p {
        text-align: center;
        margin: 0;
        font-size: 2.2rem;
        color: var(--base-color);

        @media (min-width: 1200px) and (max-width: 1400px) {
            font-size: 1.3rem;
        }

        span {
            font-size: 60px;
            display: block;
            text-align: center;
            font-weight: bold;
            color: var(--dark-pink);

            @media (max-width: 767px) {
                font-size: 40px;
            }

            @media (min-width: 1200px) and (max-width: 1400px) {
                font-size: 40px;
            }
        }
    }
}

.coming_soon_popup.modal-lg {
    .modal-body {
        padding: 50px;

        @media (min-width: 1200px) and (max-width: 1400px) {
            padding: 50px
        }

        @media (min-width: 1401px) and (max-width: 1680px) {}
    }

    .popup_team_box {
        min-height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (min-width: 1200px) and (max-width: 1400px) {
            min-height: 150px;
        }

        @media (min-width: 1401px) and (max-width: 1680px) {}
    }
}

/* -----media begins------ */


@media (max-width: 767px) {

    /* --popup css- */
    .modal-dialog.team_popup_custom .modal-content .modal-body .team_info_row {
        display: grid;
        grid-template-columns: auto;
        gap: 1rem;
        position: relative;
        align-items: center;
    }

    .modal-dialog.team_popup_custom .modal-content .modal-body .team_info_row .team_memeber-img {
        text-align: center;
        margin: auto;
    }

    .modal-dialog.team_popup_custom .modal-content .modal-body button.btn-close {
        right: -5px;
    }

    .modal-dialog.team_popup_custom .modal-content .modal-body::before {
        background-size: contain;
        height: 90%;
        top: 0px;
        left: 0;
        z-index: 0;
        width: 100%;
        transform: rotate(90deg);
    }

}


.coming_soon_popup.verification_popup {
   max-width: 450px;
   .modal-body {
    padding: 15px !important;
    &::before {
        @media (max-width:767px) {
      
        height: 100% !important;     
        
        transform: none !important;
        background-position: -15% !important;
        }
    }
   }
   
    .popup_team_box {
        min-height: 200px;
        .verification_text {
            text-align: center;
            @include font-size(2.2);
            font-weight: 600;
        }
    }
}