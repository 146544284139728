@import '../../../assets/css/base.scss';

/* ----------update profile page-- */
.update-profile-box>.row {
    align-items: center;
}

.image-preview-box {
    height: 320px;
    position: relative;
    max-width: 320px;
    margin: auto;
    border: 3px dashed var(--grey-light-border);
    @include border-radius(12.5px);
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 1499px) {
        height: 230px;
    }
   
    .imageWrapper {
        border: 3px dashed var(--grey-light-border);
        @include border-radius(50%);
        overflow: hidden;
        padding: 0px;
        min-width: 236px;
        min-height: 236px;
        max-width: 236px;
        max-height: 236px;
        text-align: center;
        position: $relative;
        @media  (max-width: 1299px) {
            min-width: 180px;
            min-height: 180px;
            max-width: 180px;
            max-height: 180px;
        }
        @media (min-width: 1300px) and (max-width: 1499px) {

            min-width: 200px;
            min-height: 200px;
            max-width: 200px;
            max-height: 200px;

        }

        @media (min-width: 1500px) and (max-width: 1599px) {

            min-width: 220px;
            min-height: 220px;
            max-width: 220px;
            max-height: 220px;

        }

        img {
            object-position: center;
            max-height: 120%;
            max-width: 120%;
            min-width: 100%;
            min-height: 100%;
            display: inline-block;
            position: $absolute;
            left: 50%;
            top: 50%;
            @include transform (translate(-50% , -50%));
        }
    }
}
.edit_profile,.update-profile-secn {
    figure {
        @media (min-width:1200px) {
            max-width: 500px;
            max-height: 500px;
            margin: 0px auto;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }
}
.image-user-name {
    .h4 {
        font-size: $f20;
        color: var(--grey-light-border);
        font-weight: $font-medium;
        margin: 0;
    }
}

.update-profile-box .upload-cstm-btns {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-height: 320px;
}

.upload-cstm-btns .btn {
    text-transform: none;
    width: 100%;
    max-width: 300px;
    min-height: 60px;

}

.upload-cstm-btns .btn img {
    max-width: 35px;
    margin-right: 25px;
}

.upload-cstm-btns .file-upload {
    position: relative;
    overflow: hidden;
    margin: 0 0 20px;
    text-align: center;
    color: var(--base-color);
    display: flex;
    align-items: center;
    -ms-transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.upload-cstm-btns .file-upload:hover {

    -webkit-box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.75);
    -moz-box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.75);
    box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.75);
}

.upload-cstm-btns .file-upload input.file-input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: $f20;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    height: 100%;
    width: 100%;
}

.passwrd_link {
    span {
        color: var(--dark-pink);
        font-size: $f18;
        cursor: $pointer;
        font-weight: $font-regular;
        text-decoration: underline;
        &:hover {
            text-decoration: underline;
            color: $base-color;
        }
    }
}

/* -media begins------ */
@media (max-width: 575.98px) {
    .upload-cstm-btns .btn {
        max-width: 100%;
    }

    form.upload-pics .save-btns .btn {
        width: 100%;
    }
}


@media (max-width: 767px) {

    .upload-cstm-btns .btn {
        min-height: 50px;

    }
}

@media (min-width: 1201px) and (max-width: 1299px) {

    .update-profile-box .upload-cstm-btns {
        max-height: 280px;
    }

    .image-preview-box {
        height: 280px;
        max-width: 320px;
    }

    .upload-cstm-btns .btn {
        min-height: 60px;
        font-size: $f16;
    }

}

@media (min-width: 1300px) and (max-width: 1399px) {
    .upload-cstm-btns .btn {
        min-height: 60px;
        font-size: $f16;
    }
}

@media (min-width: 1400px) and (max-width: 1439px) {
    .upload-cstm-btns .btn {
        min-height: 60px;
        font-size: $f20;
    }

}

@media (min-width: 1400px) and (max-width: 1499px) {


    .update-profile-box .upload-cstm-btns {
        max-height: 280px;
    }

    .image-preview-box {
        height: 280px;
        max-width: 320px;
    }
}


@media (min-width: 1500px) and (max-width: 1599px) {
    .update-profile-box .upload-cstm-btns {
        max-height: 280px;
    }

    .image-preview-box {
        height: 280px;
        max-width: 320px;
    }
}

@media (min-width: 1600px) and (max-width: 1699px) {
    .image-preview-box .imageWrapper {
        min-width: 230px;
        min-height: 230px;
        max-width: 230px;
        max-height: 230px;

    }

    .update-profile-box .upload-cstm-btns {
        max-height: 280px;
    }

    .image-preview-box {
        height: 280px;
        max-width: 320px;
    }
}

@media (min-width: 1920px) {}