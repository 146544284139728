@import "base.scss";

#eat {
	position: absolute;
	left: 1em;
	top: 1em;
	font-size: 1em;
	color: var(--dark-pink);
} 
.video.custm_video iframe {
	width: 100%;
}

// css for sign direction in circle format
.sign_direction {
	max-height: 177px;
	max-width: 160px;
	width: 83%;
	height: 100%;
	margin-left: 20px;
	// margin-top: -105px;
	cursor: $pointer;
	.main-sign>img {
		max-height: 130px;
	}
	.main-sign {
		width: 100%;
		height: 100%;
		position: $relative;
		.left_sign {
			top: 10%;
			left: 13px;
			position: $absolute;
			max-width: 70px;
			@include transform(translateX(15%));
		}
		.right_sign {
			top: 40%;
			right: 50%;
			position: $absolute;
			max-width: 70px;
			@include transform(translateX(50%));
		}
		img {
			max-width: 40px;
		}
	}
	img {
		max-width: 100%;
		height: $auto;
	}
}

.serviceOperationalState-Active,
.administrativeState-Activated,
.operationalState-Working,
.resourceLifecycleState-Operating,
.resourceUsageState-Assigned,
.powerState-PowerUp {
	background: #c1e7e3;
}

.serviceOperationalState-Active .icon,
.administrativeState-Activated .icon,
.operationalState-Working .icon,
.resourceLifecycleState-Operating .icon,
.resourceUsageState-Assigned .icon,
.powerState-PowerUp .icon {
	background: #048375;
}

.serviceOperationalState-Terminated,
.administrativeState-NotApplicable,
.resourceLifecycleState-Retiring,
.resourceUsageState-NotAvailable {
	background: #bdbdbd;
}

.serviceOperationalState-Terminated .icon,
.administrativeState-NotApplicable .icon,
.resourceLifecycleState-Retiring .icon,
.resourceUsageState-NotAvailable .icon {
	background: rgb(0, 0, 0, 0.7);
}

.serviceOperationalState-Inactive,
.administrativeState-Deactivated,
.operationalState-notWorking,
.powerState-PowerDown {
	background: #e4afb1;
}

.serviceOperationalState-Inactive .icon,
.administrativeState-Deactivated .icon,
.operationalState-notWorking .icon,
.powerState-PowerDown .icon {
	background: #8e0022;
}

.serviceOperationalState-Unknown,
.administrativeState-Unknown,
.operationalState-Unknown,
.resourceLifecycleState-Unknown,
.resourceUsageState-Unknown,
.powerState-Unknown {
	background: #9cbfd8;
}

.serviceOperationalState-Unknown .icon,
.administrativeState-Unknown .icon,
.operationalState-Unknown .icon,
.resourceLifecycleState-Unknown .icon,
.resourceUsageState-Unknown .icon,
.powerState-Unknown .icon {
	background: #012345;
}

.serviceOperationalState-Designed,
.resourceLifecycleState-Installing,
.resourceUsageState-Reserved {
	background: #ffdf81;
}

.serviceOperationalState-Designed .icon,
.resourceLifecycleState-Installing .icon,
.resourceUsageState-Reserved .icon {
	background: #8d6900;
}

.resourceLifecycleState-Installing,
.resourceUsageState-Reserved {
	background: #d9b5ed;
}

.resourceLifecycleState-Installing .icon,
.resourceUsageState-Reserved .icon {
	background: #7e06c4;
}

.node-hidden {
	display: none;
}

/** CONTEXTUAL MENU **/
.custom-menu-item {
	border: none !important;
	height: 32px !important;
	width: 170px !important;
	padding-left: 24px !important;
	color: rgb(0, 0, 0, 0.87) !important;
	background-color: #fafafa !important;
	font-weight: normal !important;
	font-size: 13px !important;
	text-align: left !important;
	box-shadow: none !important;
	&:hover {
		background-color: #c8c8c8 !important;
	}
}

.custom-context-menu {
	border: none !important;
	padding-top: 8px !important;
	padding-bottom: 8px !important;
	background-color: #fafafa !important;
	box-shadow: 0px 0px 8px 0px rgb(0, 0, 0, 0.12),
		0px 8px 8px 0px rgb(0, 0, 0, 0.24) !important;
}

/*Ovveride Navigator border and background:*/
.cytoscape-navigator {
	border: 2px solid #bebebe !important;
	background: white !important;
	height: 25vh !important;
	width: 25vw !important;
	margin-bottom: 24px;
	margin-right: 24px;
}

/*Add border to View container:*/
.cytoscape-navigator .cytoscape-navigatorView {
	border: 2px solid #124191;
	background: transparent;
}

/*Ovveride overlay container when mouse is over Navigator*/
.cytoscape-navigator:hover .cytoscape-navigatorOverlay {
	border: 2px solid #124191;
	background: transparent;
}

/*Ovveride view's container when mouse is over view*/
.cytoscape-navigator.mouseover-view .cytoscape-navigatorView {
	background-color: rgba(18, 65, 145, 0.3);
}


.node_map_secn {
	fieldset {
		legend {
			z-index: 20;
			left: 30px;
		}
	}
}


.cy {
	width: 100%;
	height: 90vw;
	position: relative;
	margin: auto;
	background: #fafafa;
	background-color: #F8F8F8;
	background-image: url("../img/Nodes/NodeBG.png") no-repeat;
	background-size: cover;
	background-position: center;

	@media screen and (max-width:1199px) {
		background-color: transparent;
	}
}

.video.custm_video iframe {
	width: 100%;
}

#eat {
	position: absolute;
	left: 1em;
	top: 1em;
	font-size: 1em;
	color: var(--dark-pink);
}

.node_map_secn {
	fieldset {
		legend {
			z-index: 0;
			left: 30px;
		}
	}
}

.nodes_Map_container {
	.commonNode {
		position: absolute;

		&.bottom {
			bottom: 0;
			left: 40%;
			max-width: 250px;
		}

		&.top {
			top: 0;
			left: 50%;
			max-width: 250px;
		}

		&.left {
			top: 10%;
			left: 0%;
			img {
				@media (min-width:1280px) {
					max-width: 75%;
				}
			}
		}

		&.right {
			top: 50%;
			right: 0%;

			img {
				max-height: 150px;
			}
		}

		img {
			max-width: 100%;
			height: auto;
		}
	}
}

.eater {	
	color: var(--white);	
	cursor: $pointer;
	@include flexbox;
	@include justify-content (center);
	@include flex-flow (column);
	@include align-items (center);
	position: $relative;
	width: 190px;
	height: 190px;
	margin-top: -30px;	
	span {
		line-height: normal;
		display: $inline-block;
		vertical-align: middle;
	}
	.common_circle {  
		padding: 5px;
		font-size: 10px; 
		@include border-radius(50%);
		text-align: $center;
		@include flexbox;
		@include justify-content (center);
		@include align-items (center); 
		position: $relative;
		overflow: $hidden;
		z-index: 1;
		min-width: 95px;
		max-width: 95px;
		min-height: 100px;
		max-height: 100px;		
		span {
			width: 100%;
			height: 100%;
			padding: 8px;
			line-height: 1.1; 
			color: var(--base-color);
			font-size: clamp(0.50rem, 0.6vw, 1rem);
			overflow-x: $hidden;
			overflow-y: $auto; 
			word-wrap: break-word;
		    word-break: break-word;
			text-transform: $uppercase;
			@include flexbox;
			@include justify-content ($center);
			@include align-items ($center);
			position: $absolute;
			top: 0; left: 0;
			z-index: 11;
			@media (max-width:767px) {
				font-size: clamp(0.95rem, 0.6vw, 1rem);
			}
			&::-webkit-scrollbar {
				width: 2px;
				height: 2px;
			}			
		}
		&.circlePink {			
			span {
				color: #F8F8F8; 
			}
		}
		&.circleGrey {
			background-color: var(--grey);
		}
		&.linked_node {
			overflow: visible;
			.linked_node_outer {
				position: $absolute;
				content: "";			
				left: 50%;
				top: 50%;
				transform: translateX(-50%) translateY(-50%);
				display: $block;
				width: 85px;
				height: 85px; 
				border-radius: 50%;
				z-index: 3;
				background: #3461b6;
				animation: pulse-border 1500ms ease-out infinite;
				.linked_node_inner {
					position: $absolute;
					content: "";			
					left: 50%;
					top: 50%;
					transform: translateX(-50%) translateY(-50%);
					width: 85px;
					height: 85px;
					@include border-radius(50%);
					z-index: 1;
					display: $block;
					background: #3461b6;
					transition: all 200ms;
					animation-delay: 1s;
				}
			}
			
			.hypernode_shine {
				z-index: 5;
			}
		}
	}
	
	//css for hypernode base added 10-11-22 with 3d effect
	&.hypernode {
		.common_circle {
			background-image: url('../img/hyperNode03.png'); 
			background-size: 100%;
			background-repeat: $no-repeat;
			background-position: $center;	 
			cursor: $pointer;
			box-shadow: 0 2.8px 2.2px rgb(0 0 0 / 4%), 0 6.7px 6.3px rgb(0 0 0 / 17%);
			.hypernode_shine {
				// edited 22-11
				&::before{
				top: 15%;
				left: 34%;
				width: 15px;
				height: 15px;
				border-radius: 50px;
				background: radial-gradient(circle at 50% 0px, #ffffff, rgb(255 255 255 / 48%) 58%);
				filter: blur(4.1px);
				-webkit-filter: blur(4.1px);
				opacity: 1;
			}
			&::after{
				// opacity: 1;
			}
			
		}
		}
		// 3d ends
		.hypernode_shine_inner {					
			width: 100%;
			height: 100%;
			position: $absolute; 
			right: 0; left: 0; top: 50%; 
			transform: translateY(-50%);
			@include border-radius (50%);
			background: transparent;
			box-shadow: inset 0px -7px 6px rgb(255 255 255 / 28%);
			z-index: 1;
			&::before, &::after {
				content: '';
				position: $absolute;
				bottom: 10px;
				right: 14px;
				width: 6px;
				height: 7px;
				@include opacity (.5);
				background:#fdfafa;
				@include transform (rotate(45deg));
				@include border-radius (4px 5px 5px);
			}		
			&::after { 
				bottom: 8px;
				right: 22px;
				width: 3px;
				height: 3px;					
			} 
		}
	}
	.hypernode_shine {
		margin: 5px;		
		position: $absolute;
		bottom: 0; right: 1px;
		top: 0px;
		@include border-radius (50%);
		box-shadow: inset 0px -8px 18px rgb(0 0 0 / 15%); 
		width: 83px;
		height: 83px;
		overflow: $hidden;
		-webkit-filter: brightness(120%);
		filter: brightness(120%);	
		
		&::before {
			content: "";
			position: absolute;
			top: 1%;
			left: 10%;
			width: 60%;
			height: 60%;
			border-radius: 50%;
			background: radial-gradient(circle at 50% 0px, #ffffff, rgb(255 255 255 / 0%) 58%);
			-webkit-filter: blur(12px);
			filter: blur(12px);
			z-index: 2;
			opacity: 0.6;
		}
		&::after {
			content: "";
			position: absolute;
			background: radial-gradient(circle at 24% 20%, rgba(255, 255, 255, 0.2), rgb(48 48 48 / 36%) 70%);
			// radial-gradient(circle at 24% 20%, rgba(255, 255, 255, 0.2), rgb(48 48 48 / 36%) 70%)
			border-radius: 50%;
			bottom: 2.5%;
			left: 5%;
			opacity: 0.6;
			height: 100%;
			width: 90%;
			-webkit-filter: blur(8px);
			z-index: 2;				
		}
	}

	.imgGrey {
		filter: grayscale(100%);
		width: 120px;
		height: $auto;
		max-width: 100%;
		margin-top: -15px;
	}
	.base {
		min-width: 65px;
		min-height: 65px;
		text-align: $center;
		font-size: 10px;
		max-height: 65px;
		margin-left: 30px;
		width: 120px;
		height: $auto;
		max-width: 100%;
		margin-top: -15px;
	}
	.displayNone {
		display: $none;
	}
	.logo_img {
		position: $absolute;
		top: -99px;
		z-index: 11;
		max-width: 60%;
	}


	&.elementToFadeInAndOut {
		-webkit-animation: fadeinout 0.5s infinite alternate;
		animation: fadeinout 0.5s infinite alternate;
	}
}

.animated_logo {
	max-width: 60px;
	position: $absolute;
	top: -10px;
	z-index: 100000;
	left: 65px;
}

/** CONTEXTUAL MENU **/
.custom-menu-item {
	border: none !important;
	height: 32px !important;
	width: 170px !important;
	padding-left: 24px !important;
	color: rgb(0, 0, 0, 0.87) !important;
	background-color: #fafafa !important;
	font-weight: normal !important;
	font-size: 13px !important;
	text-align: left !important;
	box-shadow: none !important;
}

.custom-menu-item:hover {
	background-color: #c8c8c8 !important;
}

.custom-context-menu {
	border: none !important;
	padding-top: 8px !important;
	padding-bottom: 8px !important;
	background-color: #fafafa !important;
	box-shadow: 0px 0px 8px 0px rgb(0, 0, 0, 0.12),
		0px 8px 8px 0px rgb(0, 0, 0, 0.24) !important;
}

/*Ovveride Navigator border and background:*/
.cytoscape-navigator {
	border: 2px solid #bebebe !important;
	background: white !important;
	height: 25vh !important;
	width: 25vw !important;
	margin-bottom: 24px;
	margin-right: 24px;
}

/*Add border to View container:*/
.cytoscape-navigator .cytoscape-navigatorView {
	border: 2px solid #124191;
	background: transparent;
}

/*Ovveride overlay container when mouse is over Navigator*/
.cytoscape-navigator:hover .cytoscape-navigatorOverlay {
	border: 2px solid #124191;
	background: transparent;
}

/*Ovveride view's container when mouse is over view*/
.cytoscape-navigator.mouseover-view .cytoscape-navigatorView {
	background-color: rgba(18, 65, 145, 0.3);
}

.z_index_increase {
	z-index:10
}

// cssf or hypdernode popup 14-11-22

// css for hypernode pipip
.Modal.hypernode_modal {
	max-width: 440px;
	width:100%;
	@media (max-width:575px) {
		height: 100%;
	}
	.react_modal_content {
		width:100% !important;
		padding:20px 25px 20px;
		border-radius: 10px;
		@media screen and (max-width:767px) {
			padding:15px;
		}
		h5 {
			padding-bottom: 5px;
			text-transform: $capitalize;
			text-align: center;
			font-size: $f20;
			line-height: 1.3;
			font-weight: $font-medium;
			@media (max-width:1199px) {
				font-size: $f20;
				padding-right: 40px;
			}
		}
	}
}


.hypernode_modal .react_modal_content label {
	font-size: calc(2rem - 2px);
    font-weight: 600;
    outline: 0;
    line-height: 1.3;
	@media (max-width:991px) {
		font-size: $f16;
	}
}

.hypdernode_cates_fields {
	margin:0;
	.MuiFormGroup-row {
		margin-top: 10px;
		.radio_label {
			flex: 0 0 100% !important;			
		}
		label {
			margin-bottom: 8px;
			@include align-items(start);
			@media (min-width:1280px) {
				width: 100%;
				align-items: start;
			}			
			span {
				font-size: $f16;
				padding-top: 0;
				&.MuiFormControlLabel-label {
					line-height: 1.3;
				}
				svg {
					width:20px;
					height:20px;
				}
			}
		   
		}
	}
	&_4 {
		.MuiFormGroup-row {			
			.radio_label {
				flex: 0 0 50% !important;			
			}
			label {
				@media (min-width:1280px) {
					width: 48.5%;
					align-items: start;
				}	
			
			   
			}
		}
	}
}
// .hypdernode_cates_fields .MuiFormControl-root .MuiFormGroup-row label {
//     margin-bottom: 0;
// }
// .hypdernode_cates_fields .MuiFormControl-root .MuiFormGroup-row label span {
//     font-size: $f16;
// }
// .hypdernode_cates_fields .MuiFormControl-root .MuiFormGroup-row label span svg {
// 	width:20px;
// 	height:20px;
// }




// key Frame Fade In Out
@-webkit-keyframes fadeinout {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0.2;
	}
}

@keyframes fadeinout {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0.2;
	}
}

.poleWithBubble {
	.sign_direction {
		.main-sign {
			left: 140px !important;
    		bottom: 80px !important;
		}
	}
}


@-webkit-keyframes pulse-border {
	0% {
	  -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
	          transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
	  opacity: 1;
	}
	100% {
	  -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.25);
	          transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.25);
	  opacity: 0;
	}
  }
@keyframes pulse-border {
	0% {
	  -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
	          transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
	  opacity: 1;
	}
	
	100% {
	  -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.25);
	          transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.25);
	  opacity: 0;
	}
  }

