@import '../../../assets/css/base.scss';

.react-select {
    &-container {
    
    }
    &__menu-list{
        
    }
    &__option {
            overflow: $hidden;
            text-overflow: ellipsis;
            white-space: nowrap;        
    }
}
.react-select-container {
    .react-select__menu-list {
        .react-select__option--is-selected {
            background-color: var(--sky-blue-dark);            
        }
    }
    .react-select {
        cursor: $pointer;
        &__control {
            max-height: 130px;
            overflow: $auto;
          
            @include border-radius(12.5px);
            @include font-size(1.8);
            color: var(--base-color);
            cursor: $pointer;
            appearance: $none;
            -webkit-appearance: $none;
            -moz-appearance: $none;
            padding-right: 35px;
            background: url('../../../assets/img/arrrow-icon.png') no-repeat var(--light-white);
            background-position: calc(100% - 18px);
            background-size: 15px;
            border: $none;
            box-shadow: $none!important;
            text-align: $left;
            @media (max-width: 1199px) {
              background-size: 12px;
                font-size: $f16;
            }
            @media(min-width: 1200px)
            {
                min-height: 48px;
            }
            @media(max-width: 1199.98px)
            {
                min-height: 45px;
            }
            @media(min-width: 1200px) and (max-width: 1400px) {
                @include font-size(1.6);
                background-size: 12px;
            }
            @media(min-width: 1401px) and (max-width: 1680px) {
                font-size: $f16+0.3;
                background-size: 12px;
            }
            
        }
        &__menu {
            background-color: $white-color;
            z-index: 10;
            &-list {
                .react-select__option {
                    @include font-size(1.6);
                    cursor: $pointer;
                    @media (min-width: 1200px) {
                        @include font-size(1.8);
                    }
                  
                }
            }
        }
        &__value-container {
            @media (max-width: 1199px) {
                margin: 0;
                padding-top: 0;
                padding-bottom: 0;
            }
            @media (min-width: 1201px) and (max-width: 1680px) {
                margin: 0;
                padding-top: 0;
                padding-bottom: 0;
            }
        }
        &__value-container, &__input-container, &__input {
            @media (max-width: 1199px) {
                // min-height: 40px;
                // height: 40px;
                margin: 0;
                
            }
           
        }
        &__indicators {
            display: $none;
        }
    }
}
// for drodpnw options

@media (min-width: 1280px) and (max-width: 1400px) {
    .react-select-container .react-select__control .react-select__value-container .react-select__input-container {
        margin: 0;
        padding: 0;
    }
    .react-select-container .react-select__control .react-select__value-container {
        padding-top: 0;
        padding-bottom: 0;
    }
}

@media (min-width: 1401px) and (max-width: 1680px) {
    
    .react-select-container .react-select__control .react-select__value-container .react-select__input-container {
        margin: 0;
        padding: 0;
    }

    .react-select-container .react-select__control .react-select__value-container {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.react-select-container {
    margin-bottom: 10px;
    @media (min-width: 1200px) and (max-width: 1680px) {
        margin-bottom: 10px;
    }
}
.react-select-container .react-select__control .react-select__placeholder {
    color: var(--base-color);
    text-transform: $capitalize;
}

.react-select-container .react-select__control.react-select__control--menu-is-open {
    border-color: $transparent;
    box-shadow: $none;
}