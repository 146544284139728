// _variable.scss

$center: center;
$left: left;
$right: right;
$top: top;

$none: none;
$block: block;
$inline-block: inline-block;

$relative: relative;
$absolute: absolute;
$static: static;
$fixed: fixed;

$pointer: pointer;

$visible: visible;
$hidden: hidden;
$scroll: scroll;
$auto: auto;

$capitalize: capitalize;
$uppercase: uppercase;
$lowercase: lowercase;
$inherit: inherit;
$underline:underline;
$contain: contain;
$cover: cover;

$repeat: repeat;
$no-repeat: no-repeat;


// sizes
$size-400: 400px;
$size-200: 200px;