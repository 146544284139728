
@import "../../../assets/css/base.scss";

// search wrapper wuith icon
.search_wrapper {
    @media (max-width:575.98px) {
        width: 100%;
    }
    input {
        min-height: 40px;
        padding-right: 70px;
        min-width: 280px;
        @media (min-width:1200px) {
            min-height: 42px;
        }        
    }
    .search_action {
        position: $absolute;
        right: 15px;
        top:50%;
        transform: translateY(-50%);
        @include flexCenter;
        gap:5px;
    }
    .search_icon {
        // position: $absolute;
        // right: 15px;
        // top:calc(50% - 20px/2);
        @include flexCenter;
        img {
            max-width: 20px;
        }
    }
}

.closeButtonIcon {
    // position: $absolute;
    // left: 10px;
    // z-index: 1000000;
    // top:calc(50% - 28px/2);
    font-weight: bolder;
    font-size: inherit;
    background:var(--dark-pink) ;
    @include border-radius(50%);
    cursor: pointer;
    width: 25px;
    height: 25px;
    @include flexCenter;
    svg {
        color: rgba($white-color, 0.85);
        width: 18px;
        height: 18px;
    }
}