@import '../../../assets/css/base.scss';

// .calender_time_col {
//     .cstm_Datetime-picker {
//         width:100%;
//         // .MuiInputBase-formControl {
//         //     padding: 0 !important;
            
//         //     input {
//         //         font-size:calc($f20 + 0.1vw);
//         //         padding: 0 !important;
            
//         //     }
//         // }
//         // .MuiInputAdornment-root {
//         //     margin-right: 8px;
//         //     button {
//         //       background:  rgba(0, 0, 0, 0.08);
//         //         svg {
//         //             width:3rem;
//         //             height:3rem;
//         //         }
//         //     }
//         // }
//     }
// }

.cstm_Datetime-picker 
 {
    > div {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        @include justify-content(flex-end);
        @media (max-width:767px) {
            @include justify-content(flex-start);
        }
        @media (max-width:575px) {
           width: 100%;
        }
    }
    .MuiInputBase-formControl {
        .MuiInputAdornment-root {
            margin-right: -22px !important;
            @media (max-width:360px){
                margin-right: -9px !important;
            }
        }
    }
 }
 