@import '../../../assets/css/base.scss';

$login_bg_md: url(../../../assets/img/main_bg.png);
$login_bg_lg: url(../../../assets/img/login_bg_final.png);
$login_bg_sm: url(../../../assets/img/login_bg_1200.png);

.common-login-img {
	position: $fixed;
	bottom: 0px;
	left: 0;
	padding: 100vh 16vh;
	z-index: -1;
	background: $login_bg_md $no-repeat;
	background-size: 100%;
	background-position: bottom;
	width: 100%;

	@media (min-width: 768px) and (max-width: 1199px) {
		padding: 100vh 10vh;
	}

	@media (min-width:1280px) {
		background: $login_bg_lg $no-repeat;
		background-position: bottom;
		width: 100%;
		background-size: 100%;
	}

	@media (min-width:1200px) and (max-width: 1350px) {
		padding: 100vh 10vh;
		background: $login_bg_sm $no-repeat;
		background-position: left bottom;
		background-size: 100%;
	}

	@media (min-width:1367px) and (max-width: 1439px) {
		padding: 100vh 10vh;
		background-size: 112%;
	}

	@media (max-width: 767px) {
		padding: 100vh 10vh;
	}
}

// custom form box
.custom_form_box {
	margin-top: 0px;
	text-align: $center;
	margin-right: 30px;
	max-width: 400px;
	width: 100%;
	margin-left: auto;

	@media (min-width: 992px) and (max-width: 1199px) {
		max-width: 400px;
		margin: 0px auto;
	}

	@media (min-width:1200px) and (max-width: 1680px) {
		max-width: 300px;
		margin-right: 40px;
		margin-bottom: 30px;
	}

	@media (max-width: 767px) {
		margin-top: 0px;
		margin-right: 0;
		margin-bottom: 40px;
	}

	h1,
	.h2 {
		color: var(--base-color);
		margin: 0px;

		@media (min-width: 1200px) {
			font-size: calc($f16 + 0.9vw);
		}

		@media (min-width: 1900px) {
			@include font-size(3.8);
		}

		@media (max-width: 991px) {
			@include font-size(3);
		}
	}

	.welcome_line {
		color: var(--dark-pink);
		font-size: $f20 + 0.6;
		line-height: 1.2;
		margin: 0;

		@media (max-width: 1680px) {
			@include font-size(1.6);
		}

		hr {
			width: 50%;
			margin: 0px $auto;
			height: 2px;
			color: var(--grey-lighter);
			opacity: 1;
		}
	}
}

.sign-custom-form {
	display: $block;
	margin-top: 35px;

	@media (max-width: 767px) {
		margin-top: 20px;
	}

	@media (min-width:1280px) and (max-width: 1680px) {
		margin-top: 10px;
	}
}

// Show/Hide Pwd Icon
.password-view {
	input {
		padding-right: 35px;
	}

	.pwd-view-img {
		position: $absolute;
		top: calc(50% - 20px/2);
		right: 12px;
		color: var(--black-light-500);
		cursor: $pointer;

		img {
			max-width: 20px;
			display: $block;
		}
	}
}

// forget password a tag css
.custom_form_box .forget-passwrd a {
	@include font-size(1.6);
	color: var(--base-color);
	text-decoration: $none;

	@media (min-width: 1201px) and (max-width:1440px) {
		@include font-size(1.7);
	}

	@media (min-width: 1440px) {
		@include font-size(1.8);
	}

}

// error css during login
.error {
	color: var(--dark-red);
	font-size: $f16;
	position: relative;
	text-align: left;

	@media (max-width: 1199px) {
		font-size: $f16 - 0.2;
	}

	@media (min-width: 1200px) and (max-width: 1400px) {
		font-size: $f16 - 0.4;
	}

	@media (min-width: 1401px) and (max-width: 1680px) {
		font-size: $f16 - 0.2;
	}
}

.sign-custom-form input[type="checkbox"] {
	min-height: $auto;
}

.OR {
	.seperate-or {
		position: $relative;
		margin-bottom: 0;

		&::after {
			position: $absolute;
			width: 100%;
			left: 0;
			height: 2px;
			background: var(--grey-lighter);
			content: '';
			top: 50%;
			z-index: -1;
		}

		span {
			position: $relative;
			display: $inline-block;
			background: var(--white);
			color: var(--base-color);
			width: fit-content;
			padding: 0px 15px;
			font-weight: 400;
			font-size: $f20 + 0.4;

			@media (min-width: 1201px) and (max-width:1279px) {
				font-size: $f20 + 0.1;
			}

			@media (min-width: 1441px) and (max-width:1680px) {
				font-size: $f20 + 0.1;
			}

			@media (min-width: 1280px) and (max-width:1440px) {
				font-size: $f20 - 0.3;
			}
		}
	}
}

.social_account-login {
	margin: 0;

	h4 {
		margin: 10px 0;
		font-size: $f20 + 0.2;
		font-weight: $font-regular;

		@media (min-width: 1200px) and (max-width: 1400px) {
			margin: 5px 0;
		}

		@media (min-width: 1280px) and (max-width: 1400px) {
			font-size: calc($f20 - 0.2vw);
		}

		@media (max-width: 767px) {
			font-size: $f20 - 0.2;
		}
	}
}

.social_list {
	margin-top: 15px;

	@media (min-width: 1280px) and (max-width: 14000px) {
		margin-top: 10px;
	}
	ul {
		li {
			padding: 0px 5px;
			img {
				max-width: 37px;
				max-height: 37px;
				width: 100%;
			}
		}
	}

	button {
		border: none;
		background: transparent;
		padding: 0;
	}
}

.sign-custom-form .btn.btn-signin {
	width: 100%;
}
.sign-custom-form .btn.btn-signup {
	color: var(--base-color);
	margin: auto;

	@media (min-width: 1401px) and (max-width: 1680px) {
		font-size: $f16+0.5;
		min-width: 180px;
		min-height: 40px;
	}
}

.sign-custom-form form .invalid-feedback {
	text-align: $left;
}

/* -------signup page---css-25-05-2022--- */
.custom_form_box .welcome_line a.login_link {
	text-decoration: $none;
	margin-left: 10px;
	border-bottom: 2px solid var(--sky-blue-dark100);
	color: var(--sky-blue-dark100);
	line-height: normal;
}

.custom_form_box.signup-step1 .welcome_line::after,
.custom_form_box.signup-step3 .welcome_line::after {
	position: absolute;
	left: calc(50% - 86px);
	background: var(--grey-lighter);
	content: '';
	height: 3px;
	bottom: 0px;
	width: 191px;
	display: $none;
}

.welcome_line {
	position: $relative;
}

.custom_form_box.signup-step3 .welcome_line {
	padding-bottom: 25px;
}

// custom checkbox css-------------
.custom_check {
	position: $relative;

	input {
		padding: 0;
		height: 25px;
		width: 25px;
		position: $absolute;
		top: 0;
		left: 0;
		opacity: 0;
		z-index: 11;
		cursor: $pointer;
	}

	label {
		position: $relative;
		text-transform: $none;
		display: inline;
		text-align: $left;
		color: var(--dark-pink);
		line-height: 1.3;
		margin-left: 10px;
		font-size: $f16+0.2;

		@media (max-width: 767px) {
			font-size: $f16;
			line-height: 19px;
			margin-left: 5px;
		}

		@media (min-width: 1201px) and (max-width: 1400px) {
			font-size: $f16 - 0.2;
			line-height: 1.2;
			margin-left: 0px;
		}

		u {
			color: var(--primary-color) !important;
			text-decoration: $underline;
			cursor: $pointer;
			position: $relative;
			z-index: 10;
			margin-left: 5px;
		}
	}

	.form-checks {
		span {
			padding: 10px;
			cursor: $pointer;
			margin-right: 5px;
			display: $inline-block;
			position: $relative;
			vertical-align: middle;
			@include border-radius (50%);
			background-color: var(--grey);

			@media (min-width: 1681px) {
				width: 25px;
				height: 25px;
			}

			@media (max-width: 1680px) {
				width: 20px;
				height: 20px;
			}

			@media (min-width: 768px) and (max-width:991px) {
				top: 0px;
			}
		}
	}
}

.check_row {
	@include flexbox;

	.form-checks {
		position: $relative;
		width: 25px;
		height: 25px;
	}
}


.custom_check .form-checks {
	input:checked+.checkmark:after {
		content: '';
		display: $block;
		position: $absolute;
		left: 9px;
		top: 5px;
		width: 7px;
		height: 13px;
		border: solid var(--white);
		border-width: 0 3px 3px 0;
		@include transform(rotate(45deg));

		@media (min-width: 1401px) and (max-width:1680px) {
			top: 3px;
			left: 8px;
		}

		@media (max-width: 1400px) {
			left: 7px;
			top: 3px;
			width: 7px;
			height: 13px;
			@include transform(rotate(45deg) scale(0.8));
		}

	}
}

.custom_check input:checked+span {
	background: var(--dark-pink);
}

/* -------css for signup-step2--- */
.sign-custom-form.step-2-form {
	clear: both;
	margin-top: 0px;
	min-height: 400px;
	@include flexCenter;
}

.sign-custom-form.step-2-form form.row {
	@include justify-content(center);

}

.sign-custom-form.step-2-form form .row.boxed-options {
	padding: 30px 0;
}

.sign-custom-form.step-2-form form .row.boxed-options .btn {
	width: 100%;
}

.sign-custom-form.step-2-form p {
	line-height: 1.2;
	margin: 0;
}

.sign-custom-form.step-2-form {
	.text_line_journey {
		p {
			font-size: $f20+0.4;
			margin: 0;
			color: var(--base-color);

			@media (max-width: 767px) {
				font-size: $f16;
			}

			@media (min-width: 768px) and (max-width: 1199px) {
				font-size: $f16 + 0.2;
			}

			@media (min-width: 1200px) and (max-width: 1400px) {
				font-size: $f16 + 0.4;
			}

			@media (min-width: 1401px) and (max-width: 1680px) {
				font-size: $f16 + 0.3;
			}
		}
	}

	.profile_text {
		p {
			line-height: 1.2;
			font-size: $f20+0.4;

			@media (max-width: 767px) {
				font-size: $f16;
			}

			@media (min-width: 768px) and (max-width: 991px) {
				font-size: $f16 + 0.2;
			}

			@media (min-width: 992px) and (max-width: 1199px) {
				font-size: $f16 + 0.2;
			}

			@media (min-width: 1200px) and (max-width: 1279px) {
				// font-size: 1.25rem;
				font-size: $f16 + 0.4;
			}

			@media (min-width: 1280px) and (max-width: 1400px) {
				font-size: $f16 + 0.4;
			}

			@media (min-width: 1401px) and (max-width: 1680px) {
				font-size: $f16 + 0.3;
			}
		}
	}
}

.boxed-options label {
	display: inline-block;
	width: 200px;
	padding: 10px;
	border: solid 2px #ccc;
	transition: all 0.3s;
	background: #cccccc;
}

.boxed-options input[type="radio"] {
	display: none;
}

.boxed-options input[type="radio"]:checked+label {
	border: solid 2px green;
	background: #ccc;
}

.boxed-options .btn {
	@media (min-width: 1681px) {
		min-height: 50px;
	}
}


/* -------------step3 form- cancer journey steps-------- */
.custom_form_box.signup-step3 {
	max-width: 600px;
	width: 100%;

	@media (min-width: 992px) and (max-width: 1199px) {
		max-width: 400px;
		margin: 0px auto;
	}

	@media (min-width:1200px) and (max-width: 1400px) {
		max-width: 350px;
		margin-right: 40px;
		margin-bottom: 30px;
	}

	@media (min-width:1401px) and (max-width: 1680px) {
		max-width: 350px;
	}
}

.sign-custom-form {
	.questions-list {
		.form-control {
			min-height: 50px;
			margin-bottom: 10px;

			@media (max-width: 767px) {
				min-height: 35px;
			}

			@media (min-width: 768px) and (max-width: 1680px) {
				min-height: 40px;
			}

			@media (min-width: 1200px) and (max-width: 1400px) {
				min-height: 35px;
				font-size: calc($f16 + 0.1vw);
			}
		}

		label {
			display: block;
			text-align: left;
			font-size: $f20 + 0.6;
			color: var(--base-color);
			line-height: 1.3;
			margin-bottom: 10px;

			@media (min-width: 1200px) and (max-width: 1279px) {
				font-size: calc($f16 + 0.1vw);
			}

			@media (min-width: 1280px) and (max-width: 1400px) {
				font-size: calc($f16 + 0.1vw);
			}

			@media (min-width: 1401px) and (max-width: 1680px) {
				font-size: calc($f16 + 0.3vw);
			}

			span {
				color: var(--dark-pink);
			}
		}
	}
}

// react dropdwon
.custom_form_box.signup-step3 .sign-custom-form form.row [class*="col-"] {
	margin-bottom: 15px;
}

.custom_form_box .sign-custom-form form.row [class*="col-"]:not(:last-child) {
	margin-bottom: 5px;
}

.custom_form_box.signup-step3 .sign-custom-form form.row [class*="col-"]:not(:last-child) {
	margin-bottom: 10px;
}

.sign-custom-form.step-2-form form.row [class*="col-"]:not(:last-child) {
	margin-bottom: 0;
}

/**forgot page **/
.forgot-title {
	color: var(--base-color);
	text-transform: capitalize;
}

/** otp **/
.otp-block {
	border: 2px solid #f8f9f9;
	padding: 10px;
	border-radius: 12.5px;
	@include flexCenter;
	min-height: 80px;
	max-width: 120px;
}

.otp-sec {
	margin-bottom: 1rem;
}

.otp-sec .form-control {
	/*min-height: 80px;*/
	text-align: center;
	font-size: 2.76rem;
	font-weight: 900;
}

/* -media begins------ */
@media (max-width: 575.98px) {
	.forgot-btn {
		flex-wrap: wrap;
	}

	.forgot-btn .btn {
		width: 100%;
	}

	.otp-block {
		min-height: 28px;
		max-width: inherit;
		min-width: 61px;
		padding: 5px;
	}

	.otp-sec .form-control {
		font-size: 1.5rem;
	}
}


@media (max-width: 767px) {

	.desk-sidebar li.search-bar .input-group input::placeholder {
		font-size: $f16;
	}

	.sign-custom-form {
		margin-top: 15px;
	}

	.sidebar_right {
		width: 100%;
		margin-left: auto;
	}

	.OR h3 span {
		padding: 0px 5px;
	}

	/* ---------signup step3 css-- */
	.custom_form_box.signup-step3 .sign-custom-form form label span,
	.custom_form_box.signup-step3 .sign-custom-form form label,
	.custom_form_box.signup-step3 .form-select {
		font-size: 1.15rem;
	}
}

@media (min-width: 576px) and (max-width: 767px) {
	.custom_form_box {
		margin-left: auto;
		margin-right: auto;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.custom_form_box {
		max-width: 60%;
		margin: 0px auto;
	}

	.otp-sec .form-control {
		font-size: 2rem;
	}
}

@media (min-width: 1200px) and (max-width: 1279px) {
	.sign-custom-form .form-group {
		margin-bottom: 0.75rem;
	}

	.form-checks label span {
		line-height: 20px;
	}

	.sign-custom-form .btn {
		min-height: 40px;
		font-size: $f16+0.3;
		height: 40px;
	}

	// question page
	.sign-custom-form.questions-box .btn-signup {
		margin: 0 !important;
		font-size: $f12;
		width: 100%;
	}

	.sign-custom-form {
		margin-top: 10px;
	}
}

@media (min-width: 1280px) and (max-width: 1400px) {
	.sign-custom-form .form-group {
		margin-bottom: 0.875rem;
	}


	.form-checks label span {
		line-height: 20px;
	}

	.form-checks input:checked+label:after {
		left: 7px;
		top: 4px;
		width: 6px;
		height: 11px;
	}

	.form-checks label:before {
		width: 17px;
		height: 17px;
	}

	.sign-custom-form.questions-box .btn-signup {
		margin: 0 auto;
		font-size: calc($f12 + 0.3vw);
	}

}

@media (min-width: 1401px) and (max-width: 1680px) {
	.sign-custom-form .form-group {
		margin-bottom: 1.1rem;
	}

	.sign-custom-form input,
	.sign-custom-form .questions-list .form-control {
		min-height: 40px;
		padding: 0.25rem 0.75rem;
	}
}

@media (min-width: 1200px) and (max-width: 1366px) {

	.custom_form_box .OR {
		margin-bottom: 5px;
	}

	.custom_form_box .login_btn {
		margin-bottom: 10px;
	}

	.custom_form_box .forget-pwd {
		margin-bottom: 7px;
	}

	.social_list img {
		max-width: 35px;
	}

	// question page
	.custom_form_box.signup-step3 .welcome_line {
		padding-bottom: 15px;
	}

	.sign-custom-form .common_btn {
		margin-bottom: 10px;
	}

}

@media (min-width: 1300px) and (max-width: 1500px) {
	.personalize-btn {
		font-size: 1rem;
	}
}

@media (min-width: 1400px) {
	.signup-step3 .sign-custom-form.questions-box {
		padding: 0 10px;
		margin-top: 0;
	}
}

// common modal css
.custom_modal h5 {
	padding-right: 40px;
	font-weight: $font-regular;
	line-height: 1.1;
}