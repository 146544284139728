@import "../../../assets/css/base.scss";
.comment_box {
    background: #f1f8ff;
    padding: 20px;
    @include border-radius(10px);
    @media (max-width:767px) {
        padding:15px;
    }
    .form-control {
        background-color: var(--white);
        border:1px solid #ebebeb;
        font-size: $f16;
        @include border-radius(10px);
        &:focus {
            box-shadow: none;
            outline: 0;
            border-color: inherit;
        }
       &::placeholder {
            text-transform: none;
               @media (min-width: 1366px)  {
                    font-size:calc($f16);
                }
            }
    }
    .btn {      
        min-height: auto;
        width: auto;
        height: auto;
        padding:5px 15px;
        margin: 0 !important;
        text-transform: $capitalize;
        @include border-radius(30px);
        @include transition(all, 0.5s, ease-in-out);
        color:var(--light-white);
        font-size: $f16;
        &:hover {
            color:var(--white) !important;
        }
        a {
            color: inherit;
        }
        @media (max-width:991px) {
            min-height: 30px;
            padding: 2px 15px;
            min-width: 100px;
            @include font-size(1.4);
        }
    }
}

.cstm_pop {
.report_modal {
    p {
        padding-right:23px;
    }
}
}