/* @import '../../config/style-guide/colours.scss'; */

.DraftEditor-root {
  background: #f9f9f9;
}

/*
Giving the editor a oveflow
https://github.com/facebook/draft-js/issues/528
*/

.editor :global(.public-DraftEditor-content) {
  max-height: 75vh;
  overflow: auto;
  padding: 8px 16px;
  background-color: white;
}

/* Mobile devices */
@media (max-width: 768px) {

  .editor :global(.public-DraftEditor-content) {
    margin: 0 auto;
  }
}
