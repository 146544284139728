@import '../../../assets/css/base.scss';

.common_main_nav {
    .tabs-lists {
        @include flexCenter;
        @include flex-wrap(wrap);
         li {
            flex: 0 0 auto;
            width: auto;
            @include flexCenter;
            cursor: $pointer;
            text-align: $center;
            text-transform: $capitalize;
            // @media (min-width:1200px) and (max-width:1440px) {
            //     min-width:270px;
            // }
            // @media (min-width:1440px) {
            //     min-width:270px;
            // }
            // @media (max-width: 767px) {
            //     width: 100%;
            // }

            button {
                width: 100%;
                color: var(--base-color);
                min-height: 50px;
                @include border-radius(12.5px);
                @include font-size(1.6);
                padding: 1rem;
                font-weight: $font-semibold;
                line-height: 1.3;
                @media (max-width:991px) {
                    min-height: 35px;
                    @include font-size(1.6);
                }
                @media (max-width:767px) {
                    margin-bottom: 1rem;
                }

                @media (min-width: 1200px) {
                    min-height: 35px;
                    padding: 1rem;
                    @include font-size(1.7);
                }

                @media (min-width: 1440px) and (max-width:1899px) {
                    @include font-size(1.7);
                }
                @media (min-width: 1900px) {
                    @include font-size(1.8);
                }
                @media (min-width: 1440px) {
                    min-height: 48px;
                }

                &.active,
                &:hover,
                &:focus {
                    color: var(--base-color);
                    background-color: var(--lighter-red1);
                    background-color: $light-pink;
                    background-color: $pink-100 !important;
                    border-color: $light-pink !important;
                    box-shadow: 0px 7px 0px $light-pink;

                }
            }
        }
        // for two button grp
        &_2 {
            @media (max-width:767px) {
                gap: 1.5rem;
            }
            @media (min-width:768px) and (max-width:1200px) {
                gap:50px;
            }
            @media (min-width:1200px) and (max-width:1680px) {
                gap:60px;
            }
            @media (min-width:1680px) {
                gap:80px;
            }
            li {               
                text-transform: $capitalize;
                @media (min-width:576px) and (max-width:767px) {
                    min-width: 230px;
                    max-width: 280px;			
                }
                @media (min-width:768px) and (max-width:1199.98px) {
                    min-width: 230px;
                    max-width: 280px;			
                }
                @media (min-width:1200px) and (max-width:1440px) {
                    min-width: 280px;			
                }
                @media (min-width:1440px) and (max-width:1680px) {
                    min-width:320px;			
                }
                @media (min-width:1680px) {
                    min-width: 418px;
                    max-width: 418px;
                }
                @media (max-width:575.98px) {
                    min-width: 100%;
                    max-width: 100%
                }
            }
        }
        // for three btn grp
        &_3 {
            @media (max-width:767px) {
                gap:1.5rem;
            }
            @media (min-width:768px) and (max-width:1200px) {
                gap:40px;
            }
            @media (min-width:1200px) and (max-width:1680px) {
                gap:75px;
            }
            @media (min-width:1680px) {
                gap:60px;
            }            
            @media (min-width:1920px) {
                gap:80px;
            }
            li {                          
                @media (min-width:768px) {
                    flex: 1;			
                 }
                @media (min-width:576px) and (max-width:767px) {
                    min-width: 170px;
                    max-width: 180px;			
                }
               
                // @media (min-width:992px) and (max-width:1199.98px) {
                //     min-width: 280px;                   			
                // }
                // @media (min-width:1200px) and (max-width:1279px) {
                //     min-width: 250px;			
                // }
                // @media (min-width:1280px) and (max-width:1366px) {
                //     min-width: 260px;			
                // }
                
                // @media (min-width:1367px) and (max-width:1440px) {
                //     min-width: 280px;			
                // }
                // @media (min-width:1440px) and (max-width:1680px) {
                //     min-width:310px;			
                // }
                // @media (min-width:1680px) {
                //     min-width: 380px;
                  
                // }
                // @media (min-width:1920px) {
                //     min-width: 410px;                  
                // }
                @media (max-width:575.98px) {
                    min-width: 100%;                  
                }
            }
        }
        // 4 btns grp
        &_4 {
            @media (max-width:767px) {
                gap: 1.5rem;
            }
            @media (min-width:768px) and (max-width:1200px) {
                gap:20px;
            }
            @media (min-width:1200px) and (max-width:1680px) {
                gap:20px;
            }
            @media (min-width:1680px) {
                gap:20px;
            }
            li {               
                @media (min-width:576px) and (max-width:767px) {
                    min-width: 150px;
                    max-width: 180px;			
                }
                @media (min-width:768px) and (max-width:991.98px) {
                    min-width:160px;                   			
                }
               
                // @media (min-width:1200px) and (max-width:1279px) {
                //     min-width: 210px;			
                // }
                // @media (min-width:1280px) and (max-width:1366px) {
                //     min-width: 226px;			
                // }
                // @media (min-width:1367px) and (max-width:1440px) {
                //     min-width: 250px;			
                // }
                // @media (min-width:1440px) and (max-width:1680px) {
                //     min-width:260px;			
                // }
                // @media (min-width:1680px) {
                //     min-width: 320px;                  
                // }
                // @media (min-width:1920px) {
                //     min-width: 364px;                  
                // }
                @media (min-width:768px) {
                   flex: 1;			
                }
                @media (max-width:575.98px) {
                    min-width: 100%;                   
                }
            }
        }
    }
}