@import url('react-big-calendar/lib/css/react-big-calendar.css');

.week-event {
    .rbc-event-label {
        display: none;
    }
    .rbc-event-content {
        div {
            div {
                display: none;
            }
        }
    }
}