
@import "../../../assets/css/base.scss";

.date_time_picker {
    div {
        width: 100%;
        @media (max-width:575px) {
            width: 100%;
        }
    }
    input {
        background: var(--light-white);
        border: none;
        width: 100%;
        @include border-radius(12.5px);
        min-height: calc(1.5em + 2rem - 7.5px);
        color: var(--base-color);
        @include font-size(1.8);
        font-weight: $font-regular;
        outline: 0 !important;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        padding: 0.5rem 1rem;
        @media (max-width:991px) {
            @include font-size(1.6);
            
        }
        @media (max-width:575px) {
            width: 100%;
        }
        &:focus, &:active {
            border: none;
            box-shadow: none;
            background-color: var(--light-white);
            padding-right: 0;
        }
    }
   select {
        border: none;
        box-shadow: none;
        background-color: var(--light-white);
    }
  
    span svg {
      width: 80px !important;
      height: 80px !important;
      font-size: 17px !important;
    }
    }
    
    [id^=modal-content]{
    header{
        h2{
            font-size: 16px;
            color: var(--sky-blue-dark100);
        }
    }
    article{
        input{
            background: var(--sky-blue-dark100);
        }
        span{
            color: var(--sky-blue-dark100);
        }
        button svg{
            color:var(--sky-blue-dark100);
            width: 1.5rem !important;
        }
        footer button{
            background-color: var(--sky-blue-dark100);
            border-radius: 12.2px;
            font-size: 14px;
            color:#fff;
            transition: all 0.5s ease-in-out;
            padding: 0.5rem;
        }
        footer button:hover{
        background-color: var(--lighter-red) !important;
        color:#000;
        transition: all 0.5s ease-in-out;
        border-color: var(--lighter-red);
        }
    }
    section{
        table tr th,table tr td{
           font-size: 16px;
        }
        table td [aria-disabled="true"]{
            font-size: 16px;
         }
        table td {
            button[data-selected="true"] {
                background: var(--sky-blue-dark100);
                color: white;
              }
              button[data-selected="true"]:hover {
                background: var(--sky-blue-dark100);
                color: white;
              }
              button:hover {
                background-color: transparent;
                border: 1px solid var(--sky-blue-dark100);
            }
        }
        button svg{
            color:var(--sky-blue-dark100);
            width: 1.5rem;
        }
        button:focus {
            outline: 0;
            box-shadow: 0 0 4px var(--sky-blue-dark100);
        }
        h3{
            font-size: 16px;
        }
        select{
            font-size: 16px;
        }
        div {
            &+select{
            
        }
        &::after{
            color:var(--sky-blue-dark100);
        }
        }
        select:focus{
            border: 0.125rem var(--sky-blue-dark100)solid;
            box-shadow: 0 0 2px var(--sky-blue-dark100);
        }
        select:active{
            border: 0.125rem var(--sky-blue-dark100)solid;
            box-shadow: 0 0 2px var(--sky-blue-dark100);
        }
        select:visited {
            border: 0.125rem var(--sky-blue-dark100)solid;
            box-shadow: 0 0 2px var(--sky-blue-dark100);
        }
    }
   
}
#modal-close-button{
    background-color: var(--dark-pink);
    border-radius: 50px;
    width: 30px;
    height: 30px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in-out ease-in-out;
    margin-right: 6px;
    svg{
        fill:#fff !important;
        width:2rem !important;
    }
}
     
 
  
