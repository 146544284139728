@import "../../../assets/css/base.scss";

.created_at {
    font-size: 1.2rem;
    font-weight: 500;
    // position: absolute;
    // top: -16px;
    color: #666e82;
}

.reply_thread {
    margin-top: 5px;
}

.comment_username {
    font-weight: bold;
    margin-right: 10px;
}