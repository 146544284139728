// @import '../../../../assets/css/base.scss';
@import '../../../assets/css/base.scss';

.trackers_quesns {
    margin:30px 0px;
    .quesn_card {
        @include flexbox;
        @include justify-content(space-between);
        @include align-items(flex-start);
        @include flex-wrap(wrap);
        gap : 0px 20px ;
      
        .quesns_list {
            flex:0 0 calc(50% - 20px);
            width:calc(50% - 20px);
            @media screen and (max-width:767px) {
                flex: 0 0 100%;
                width: 100%;
            }
            label {
                display: $block;
                text-align: left;
                font-size: $f20;
                color: var(--base-color);
                line-height: 1.3;
                margin-bottom: 10px;
                font-weight: $font-medium;
                text-transform: unset;
                span {
                    color:var(--dark-pink);
                }
                @media (min-width: 1281px) and (max-width: 1440px) {
                    font-size: calc($f20 + 0.1vw);
                }
                @media (max-width: 1280px) {
                    font-size: calc($f16 + 0vw);
                }
    
            }
            .react-select-container {
                margin-bottom: 0;
            }
            @media (max-width:767px) {
                margin-bottom: 2rem;
            }
         }
    }
}