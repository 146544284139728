@import '../../../assets/css/base.scss';

.reset_wrapper {
  .checklist-dropdown {
    .form-control {
        @media (min-width:1200px) and (max-width:1440px)
    
        {
            min-height: 40px;
        }
    }
  }  
}