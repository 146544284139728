@import "../../../assets/css/base.scss";

.inc-index-to-2000 {
    z-index: 2000 !important;
}

.Modal {
    padding: 20px;
    margin: 0 $auto;
    background: transparent;
    outline: $none !important;

    &.modal_md {
        width: 600px;

        @media (max-width: 767px) {
            width: 94%;
        }
    }

    &.modal_lg {
        width: 1200px;

        @media (max-width: 767px) {
            width: 94%;
        }
    }

    &.react_modal_main {
         outline: $none !important;
        @include flexCenter;
        min-height: calc(100% - (1.75rem * 2));

        @media (min-width: 576px) {
            margin: 1.75rem $auto;
            min-height: calc(100% - (1.75rem * 2));
        }

        .react_modal_content {
            position: $relative;
            background: var(--white);
            padding: 15px 25px 20px;
            @include border-radius(10px);
            width: 100%;

            @media (max-width: 767px) {
                width: 100%;
                padding: 15px;
            }
            .modal
            {
                &_header {
                   h3,.h3{ 
                    margin-right: 25px;
                    width: calc(100% - 25px);
                
                   }
                }

            }
            label {
                @include font-size(1.6);
                font-weight: $font-medium;
            }
        }
    }


    .close_btn {
        @include border-radius(50px);
        position: $absolute;
        padding: 1px;
        border: 0;
        right: 15px;
        top: 15px;

        @media (max-width: 767px) {
            top: 12px;
            right: 7px;
        }

        @media (min-width: 768px) and (max-width:991px) {
            top: 10px;
        }

        span {
            background-color: var(--dark-pink);
            @include border-radius(50px);
            width: 30px;
            height: 30px;
            padding: 4px;
            @include flexCenter;
            @include transition(all, 0.5s ease-in-out);

            @media (min-width: 1200px) {
                font-size: $f16 !important;
                width: 30px;
                height: 30px;
            }

            @media (max-width: 767px) {
                width: 25px;
                height: 25px;
            }

            svg {
                width: 15px;
                height: 15px;

                path {
                    fill: var(--lighter-white);
                }

                @media (max-width: 767px) {
                    width: 12px;
                    height: 12px;
                }

                @media (min-width: 768px) and (max-width:991px) {
                    width: 15px;
                    height: 15px;
                }
            }

        }

        &:hover {
            span {
                // background: linear-gradient(135deg, var(--dark-pink) 18.18%,var(--light-pink) 79.09%);
                background: var(--dark-pink);
            }
        }
    }


}

.ReactModal {
    &__Body {
        &--open {
            overflow: $hidden;
        }
    }
}

.Overlay {
    position: $fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: $auto;
    overflow-x: $hidden;
    background: rgba(0, 0, 0, 0.65);
    @include transition(opacity, 0.15s, linear);
    z-index: 4000;
}