

.videoError {
    height: 38rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    background: rgba(0,0,0);
    color: rgb(255,255,255);
    font-size: 2rem;
    flex-direction: column;
}

.videoContainer {
    position: relative;
}

.videoCountDown {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0,0.5);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255,255,255);
    font-weight: 900;
    flex-direction: column;
}