// _fonts.scss

@font-face {
    font-family: 'Rotunda';
    src: url('../fonts/rotunda/Rotunda-Bold.eot');
    src: local('../fonts/rotunda/Rotunda Bold'), local('Rotunda-Bold'),
        url('../fonts/rotunda/Rotunda-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/rotunda/Rotunda-Bold.woff2') format('woff2'),
        url('../fonts/rotunda/Rotunda-Bold.woff') format('woff'),
        url('../fonts/rotunda/Rotunda-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rotunda';
    src: url('../fonts/rotunda/Rotunda-Medium.eot');
    src: local('../fonts/rotunda/Rotunda Medium'), local('Rotunda-Medium'),
        url('../fonts/rotunda/Rotunda-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/rotunda/Rotunda-Medium.woff2') format('woff2'),
        url('../fonts/rotunda/Rotunda-Medium.woff') format('woff'),
        url('../fonts/rotunda/Rotunda-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rotunda';
    src: url('../fonts/rotunda/Rotunda-Regular.eot');
    src: local('../fonts/rotunda/Rotunda'), local('Rotunda-Regular'),
        url('../fonts/rotunda/Rotunda-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/rotunda/Rotunda-Regular.woff2') format('woff2'),
        url('../fonts/rotunda/Rotunda-Regular.woff') format('woff'),
        url('../fonts/rotunda/Rotunda-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rotunda';
    src: url('../fonts/rotunda/Rotunda-Thin.eot');
    src: local('../fonts/rotunda/Rotunda Thin'), local('Rotunda-Thin'),
        url('../fonts/rotunda/Rotunda-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/rotunda/Rotunda-Thin.woff2') format('woff2'),
        url('../fonts/rotunda/Rotunda-Thin.woff') format('woff'),
        url('../fonts/rotunda/Rotunda-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rotunda';
    src: url('../fonts/rotunda/Rotunda-Light.eot');
    src: local('../fonts/rotunda/Rotunda Light'), local('Rotunda-Light'),
        url('../fonts/rotunda/Rotunda-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/rotunda/Rotunda-Light.woff2') format('woff2'),
        url('../fonts/rotunda/Rotunda-Light.woff') format('woff'),
        url('../fonts/rotunda/Rotunda-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rotunda Variable Font';
    src: url('../fonts/rotunda/RotundaVariable-Regular.eot');
    src: local('../fonts/rotunda/Rotunda Variable Font'), local('RotundaVariable-Regular'),
        url('../fonts/rotunda/RotundaVariable-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/rotunda/RotundaVariable-Regular.woff2') format('woff2'),
        url('../fonts/rotunda/RotundaVariable-Regular.woff') format('woff'),
        url('../fonts/rotunda/RotundaVariable-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
//segoue ui

@font-face {
    font-family: 'Segoe UI Semilight';
    src: url('../fonts/segoe/SegoeUI-Semilight.eot');
    src: local('../fonts/segoe/Segoe UI Semilight'), local('SegoeUI-Semilight'),
        url('../fonts/segoe/SegoeUI-Semilight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/segoe/SegoeUI-Semilight.woff2') format('woff2'),
        url('../fonts/segoe/SegoeUI-Semilight.woff') format('woff'),
        url('../fonts/segoe/SegoeUI-Semilight.ttf') format('truetype'),
        url('../fonts/segoe/SegoeUI-Semilight.svg#SegoeUI-Semilight') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('../fonts/segoe/SegoeUI.eot');
    src: local('../fonts/segoe/Segoe UI'), local('SegoeUI'),
        url('../fonts/segoe/SegoeUI.eot?#iefix') format('embedded-opentype'),
        url('../fonts/segoe/SegoeUI.woff2') format('woff2'),
        url('../fonts/segoe/SegoeUI.woff') format('woff'),
        url('../fonts/segoe/SegoeUI.ttf') format('truetype'),
        url('../fonts/segoe/SegoeUI.svg#SegoeUI') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Segoe UI Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Italic'), url('../fonts/segoe/SegoeUI-Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Segoe UI Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Bold'), url('../fonts/segoe/Segoe-UI-Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Segoe UI Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Bold Italic'), url('../fonts/segoe/SegoeUI-Bold-Italic.woff') format('woff');
    }
    @font-face {
        font-family: 'Segoe UI';
        src: url('../fonts/segoe/SegoeUI-Semibold.eot');
        src: url('../fonts/segoe/SegoeUI-Semibold.eot?#iefix') format('embedded-opentype'),
            url('../fonts/segoe/SegoeUI-Semibold.woff2') format('woff2'),
            url('../fonts/segoe/SegoeUI-Semibold.woff') format('woff'),
            url('../fonts/segoe/SegoeUI-Semibold.svg#SegoeUI-Semibold') format('svg');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }

//font-family
$font-Rotunda: 'Rotunda';
$font-Segoe-regular: 'Segoe UI';
$font-Segoue-semilight:  'Segoe UI Semilight';

//font-weights
$font-normal:normal;
$font-100:100;
$font-light:300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-extrabold: 900;

html {
    font-size: 62.5%; 
    // 1rem = 10px
    // multiply by 10 to 1rem = px value
}

$f12: 1.2rem; 
$f14: 1.4rem; 
$f16: 1.6rem;
$f18: 1.8rem;
$f20: 2rem;
$f24: 2.4rem;
$f30: 3rem;
$f40: 4rem;
$f50: 5rem;


// $f12: 0.65rem; 
// $f14: 0.75rem; 
// $f16: 1rem;
// $f18: 1.1rem;
// $f20: 1.25rem;
// $f24: 1.5rem;
// $f30: 1.75rem;
// $f40: 2.5rem;
// $f50: 3.25rem;