@import "../../../assets/css/base.scss";

.checklist-title {
    .back-btn {

        // @media (max-width:767px) {
        //     position: relative;
        //     left: 0;
        //     max-width: 100px;
        //     min-width: 100px;
        //     margin-bottom: 20px;
        // }
        .btn {
            @media (max-width:1199px) {
                max-width: 100px;
                min-width: 100px;
                min-height: 40px;
                margin-left: 0;
            }
        }
    }
    h1,.h2 {
        line-height: 1;
        @media (min-width:1200px) {
            margin-bottom: 0;
        }
    }
}


.cancer_light_titlebar_2_elements,
.cancer_light_titlebar_3_elements,
.cancer_light_titlebar_4_elements {
    .checklist-title {
        .back-btn {
            position: $relative;
            left: 0;

            @media (min-width:1024px) {
                // position: absolute;
                // left:0;
                // top:0;
            }
        }
    }
}

.cancer_light_titlebar {
    &_2_elements {
        .checklist-title {
            .back-btn {
                position: $relative;
                left: 0;

                @media (min-width:1024px) {
                    position: $absolute;
                    left: 0;
                    top: 50%;
                    @include transform(translateY(-50%));
                }
            }

            .article-title {
                @media (min-width:1024px) {
                    width: calc(100% - 0px);
                    margin-right: 0;
                    padding-left: 0px;
                }
            }
        }
    }

    &_3_elements {
        .checklist-title {
            display: grid;
            align-items: self-start;
            @media (min-width:992px) {
                grid-template-columns: 120px 1fr auto;
            }

        }
    }

    &_4_elements {
        .checklist-title {
            display: grid;
            align-items: self-start;
            @media (min-width:992px) {
                grid-template-columns: 120px 1fr auto;
            }

        }
    }
}

.common_titleBar {
    .checklist-title {
        .article-title {
            @media (max-width:767px) {
                margin-top: 20px;
                order: 3;
            }
        }
    }

    .additional-actions {
        position: $relative;
        @include flexbox;
        @include justify-content(flex-end);

        .MuiFormControl-root {
            &:not(:first-child) {
                @media (max-width:575px) {
                    margin-top: 12px;
                }
            }
        }

        @media (max-width:991px) {
            margin-top: 15px;
        }

        @media (max-width:767px) {
            order: 2;
        }

        @media (max-width:575px) {
            flex-flow: column;
            @include flex-flow(column);

            .btn {
                flex: 0 0 auto;
                width: 100%;
                margin-top: 10px;
            }
        }

        @media (min-width:576px) and (max-width:767px) {
            flex-flow: row;
            @include flex-flow(row);
            .btn {
                flex: 0 0 auto;
                width: calc(50% - 10px);
                margin-right: 10px;
            }
        }
    }
}