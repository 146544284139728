@import '../../../assets/css/base.scss';


// custom table common cols

.table-fixed {
    width: 100%;
    thead {
        background: -o-linear-gradient(45deg, var(--sky-blue3), var(--sky-blue));
        background: linear-gradient(45deg, var(--sky-blue3), var(--sky-blue));
        tr {
            th {
                @include font-size(1.8);
                @media (min-width:1366px) {
                    @include font-size(2);
                }
            }
        }
    }

    tbody {
        max-height: 500px;
        overflow-y: $auto;
        width: 100%;
        border: $none !important;
        td {
            @include font-size(1.6);
            padding: 15px;
            border-color: var(--grey-light-border);
            align-items: center;

            @media (min-width:1366px) {
                @include font-size(1.8);
            }

            @media (min-width: 1200px) and (max-width: 1400px) {
                padding: 15px;
            }

            &:first-child {
                padding-left: 45px;
            }

            &.action_column {
                button {
                    &.delete_acn {
                        @include border-radius(6px);
                    }
                }
            }
            &[data-column|="sNo"],&[data-column|="id"]{
                white-space:nowrap;
            }
        }
    }
}

// tow cols table td settings
.two-cols-table {
    .table-fixed {
        width: 100%;

        thead {
            tr {
                th {
                    @include font-size(1.8);
                    font-weight: $font-bold;
                    border: none !important;
                    text-align: $center;

                    @media (min-width:1366px) {
                        @include font-size(2.2);
                    }

                    @media (max-width:767px) {
                        padding: 15px;
                    }

                    &:first-child {
                        border-radius: 12.5px 0 0 12.5px;
                        padding: 20px 20px 20px 45px;
                        width: 30%;

                        @media (min-width: 1200px) and (max-width: 1279px) {
                            padding: 15px 15px 15px 45px;
                        }

                        @media (min-width: 1280px) and (max-width: 1400px) {
                            padding: 15px 15px 15px 45px;
                        }

                        @media (max-width:767px) {
                            padding: 15px;
                        }
                    }

                    &:last-child {
                        border-radius: 0 12.5px 12.5px 0;
                        padding: 20px 45px 20px 20px;
                        width: 70%;

                        @media (min-width: 1200px) and (max-width: 1400px) {
                            padding: 15px 15px 15px 45px;
                        }

                        @media (max-width:767px) {
                            padding: 15px;
                        }
                    }
                }
            }
        }

        tbody {
            max-height: 500px;
            overflow-y: auto;
            width: 100%;
            border: none !important;

            td {
                @include font-size(1.6);
                padding: 15px;
                border-color: var(--grey-light-border);
                text-align: $center;               
                word-break: break-word;
                @media (min-width:1366px) {
                    @include font-size(1.8);
                }

                @media (min-width: 1200px) and (max-width: 1400px) {
                    padding: 15px;
                }

                &:first-child {
                    padding-left: 45px;
                }
            }

            .common_btn {
                .btn {
                    @media (min-width: 768px) and (max-width: 1199px) {
                        min-width: 150px;
                        min-height: 40px;
                    }
                }

            }
        }
    }
}

// two cols table ends
// for three cols table
.three-cols-table {
    .table-fixed {
        width: 100%;

        thead {
            tr {
                th {
                    @include font-size(1.8);
                    border: none !important;
                    width: auto;
                    text-align: $center;
                    white-space: nowrap;
                    padding: 15px;
                    font-weight: $font-bold;

                    @media (min-width:1366px) {
                        @include font-size(2.2);
                    }

                    @media (min-width: 1200px) {
                        padding: 15px;
                    }

                    @media (max-width:767.98px) {
                        padding: 15px;
                    }

                    &:first-child {
                        border-radius: 12.5px 0 0 12.5px;
                        padding-left: 45px;
                        width: auto;

                        @media (min-width: 1200px) {
                            padding: 15px 15px 15px 45px;
                        }

                        @media (max-width:1199.98px) {
                            padding-left: 30px;
                        }

                        @media (max-width:767px) {
                            padding-left: 15px;
                        }
                    }

                    &:last-child {
                        border-radius: 0 12.5px 12.5px 0;
                        width: auto;
                        padding-right: 45px;

                        @media (min-width: 1200px) {
                            padding: 15px 45px 15px 15px;
                        }

                        @media (max-width:1199.98px) {
                            padding-right: 30px;
                        }

                        @media (max-width:767px) {
                            padding-right: 15px;
                        }
                    }
                }
            }
        }

        tbody {
            max-height: 500px;
            overflow-y: auto;
            width: 100%;
            border: none !important;

            td {
                @include font-size(1.6);
                padding: 15px;
                border-color: var(--grey-light-border);
                text-align: $center;             
                word-break: break-word;
                text-transform: $capitalize;
                vertical-align: middle;
                @media (min-width:1366px) {
                    @include font-size(1.8);
                }

                @media (min-width: 1200px) and (max-width: 1400px) {
                    padding: 15px;
                }


                @media (max-width: 767px) {
                    min-height: 50px;
                    padding: 15px;
                }

                &:first-child {
                    padding-left: 15px;

                    @media (min-width:1200px) {
                        padding-left: 45px;
                    }

                    @media (max-width:1199.98px) {
                        padding-left: 30px;
                    }

                    @media (max-width:767px) {
                        padding-left: 15px;
                    }
                }

                &:last-child {
                    padding-right: 15px;

                    @media (min-width:1200px) {
                        padding-right: 45px;
                    }

                    @media (max-width:1199.98px) {
                        padding-right: 30px;
                    }

                    @media (max-width:767px) {
                        padding-right: 15px;
                    }
                }

                a {
                    display: $inline-block;
                    text-transform: math-auto;

                    @media (max-width:575px) {
                        font-size: $f16;
                    }
                }

                .btn-link {
                    color: var(--sky-blue-dark100);
                    text-decoration: $none;
                    @include transition(all, 0.3s, ease-in-out);

                    &:hover {
                        text-decoration: $underline;
                    }
                }

                .calender_span,
                .time_span {
                    display: inline-flex;
                    @include align-items(center);
                    @media (max-width:767px) {
                        @include flexbox;
                        @include align-items(flex-start);
                        text-align: $left;
                    }

                    svg {
                        width: 22px;
                        height: 22px;
                    }
                }
            }

            .common_btn {
                .btn {
                    @media (min-width: 768px) and (max-width: 1199px) {
                        min-width: 150px;
                        min-height: 40px;
                    }
                }

                button {
                    &.MuiButtonBase-root {
                        @include flexCenter;
                    }

                    svg {
                        margin-right: 0 !important;
                    }

                    &:hover {
                        svg {
                            path {
                                fill: $light-pink;
                            }
                        }
                    }
                }
            }
        }
    }
}

// ends three cols table

//   .table-fixed thead, .table-fixed tbody, .table-fixed tr, .table-fixed td, .table-fixed th {
//     display: block;
//   }
//   .table-fixed tbody td {
//    float: left;
//   }
.tab-titl {
    h4 {
        color: var(--base-color);
        border-bottom: 2px solid var(--base-color);
        padding-bottom: 5px;
    }
}

//common attirbute for all tables in responsve mode (min-device-width: 768px) and (max-device-width: 1024px)
@media only screen and (max-width: 767px) {

    .table-fixed {
        width: 100%;
    }

    /* Force table to not be like tables anymore */

    /* Hide table headers (but not display: none;, for accessibility) */

    .table-fixed table tr {
        border: 1px solid var(--grey);
    }

    .table-fixed tbody td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid var(--grey-light-border);
        position: relative;
    }

}

.three-cols-table .table-fixed tbody .no-record-found {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

// common table ends
.table_pagination {
    padding-bottom: 2rem;
    @media (max-width:991px) {
        padding-bottom: 0rem;
    }

    .pagination {
        @media (max-width:1199.98px) {
            margin: 15px 0;
        }

        margin: 15px 0;

        .page-item {
            .page-link {
                @include font-size(1.6);
                padding: 5px 10px;
                text-transform: $capitalize;
                color: $base-color;
                @include flexCenter;
                width: 35px;
                height: 40px;
                @media (min-width:1200px) {
                    @include font-size(1.7);
                }

                &:focus {
                    box-shadow: $none;                   
                }
            }

            &.active {
                .page-link {
                    background-color: $primary-color;
                    color: $white-color;
                }
            }

            &.disabled {
                @include opacity(0.5);
                pointer-events: $none;

                .page-link {
                    background-color: var(--light-grey);
                }
            }
        }
    }
}

.html-link {
    text-decoration: $none;
    color: $primary-color;
}

.html-link:visited {
    text-decoration: none;
    color: $primary-color;
}

.team_actions {
    span {
        cursor: $pointer;
        padding:15px 15px 15px 5px;
        @media (max-width:767px) {
            padding:3px 5px 3px 0;
        }       
        &.preview_acn {
            color: #1dbbe6;     
            svg{
                path {
                    color: #1dbbe6;     
                }
            }      
        }
        &.edit_acn {
            color: green;       
            svg{
                path {
                    color: green;     
                }
            }      
        }
        &.delete_acn {
            color: red;     
            svg{
                path {
                    color: red;     
                }
            }      
        }
        svg {
            width: 20px;
            height: 20px;
        }
    }
    @media (max-width:767px) {
        @include flexbox;
        @include justify-content(center);
        @include align-items(center);
        @include flex-wrap(wrap);       
    }
    @media (max-width:575px) {
        @include flexbox;
        @include justify-content(center);
        @include align-items(flex-start);
        @include flex-wrap(wrap);
        @include flex-direction(column);
    }   
}
tr td.no-data{
    text-align: center !important;
    padding-left: 20px !important;
}


@media (max-width:1010px) {
    .additional-actions {
        .btn-create-team  {
            position: unset !important;
            // top: -21px !important;
        }
    }
}