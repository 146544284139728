@import '../../../assets/css/base.scss';

.common_list_sidebar ul {
    margin: 0;
    padding: 0;
}

.desk-sidebar {
    margin-top: 50px;

    @media (min-width: 1201px) {
        margin-top: 30px;
    }
}

/* Position and style the close button (top right corner) */

.menu-backdrop {
    @media (max-width:1200px) {
        position: $fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $black-color;
        opacity: 0.5;
        z-index: 999;
        width: 100%;
        height: 100vh;
    }
}

body.is_menu_active {
    @media (max-width:1200px) {
        overflow: hidden;
    }

}

/* ----cstm cs for sidebar-------- */

.sidenav {
    height: 100%;
    width: 250px;
    position: $fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    overflow-y: $auto;
    overflow-x: $hidden;
    padding-top: 0px;  
    scrollbar-width: thin;
    scrollbar-color: var(--sky-blue-dark) var(--white);
    &.active {
        @include transform(translateX(0px));       
    }

    @media (max-width:1200px) {
        width: 300px;
        @include transform(translateX(-110%));
        background-color: var(--white);
        box-shadow: 0 0 10px rgba(0, 0, 0, .5);
        overflow-y: $hidden;
        padding: 10px;
        @include transition(all, 0.5s, ease-in-out);  
    }

    @media (max-width:575.98px) {
        width: 100%;
    }

    @media (min-width: 1201px) and (max-width:1440px) {
        width: 200px;
    }

    @media (min-width: 1441px) and (max-width:1680px) {
        width: 210px;
    }
    .closebtn {
        position: $absolute;
        top: 15px;
        right: 15px;
        z-index: 99;
        font-size: 0;
        margin-left: 0px;
        border: 0px;
        background: $transparent;
        outline: $none;
        cursor: $pointer;
        display: $none;
    }
}

/* ----------sidebar css ends---- */
.siderbar_common {
    .offcanvas-header-img {
        padding-top: 10px;
        padding-left: 30px;

        img {
            max-width: 130px;

            @media (min-width: 1200px) and (max-width: 1400px) {
                max-width: 90px;
            }

            @media (min-width: 1401px) and (max-width: 1680px) {
                max-width: 100px;
            }
        }
    }

}

.common_list_sidebar {
    ul {
        li {
            border-bottom: 2px solid var(--grey-lighter);
            width: 100%;

            &:last-child {
                border-bottom: 0;
            }

            &:nth-last-child(3) {
                @media (min-width:1200px) {
                    border-bottom: 0;
                }
            }
        }
    }

}

// .common_list_sidebar ul li:nth-last-child(3){
//     border-bottom: none;
// }
.sidenav {
    .common_list_sidebar {
        ul {
            li {

                a,
                >span {
                    @include flexbox;
                    color: var(--base-color);
                    padding: 5px 10px 6px 12px;
                    text-decoration: $none;
                    font-size: calc($f20 + 0.24rem);
                    transition: transform 1s;
                    cursor: pointer;
                    line-height: 1.3;

                    @media (max-width: 1200px) {
                        font-size: $f20 - 0.2;
                        padding: 5px;
                    }

                    @media (min-width:768px) and (max-width: 991px) {
                        font-size: calc($f20 + 0.24rem);
                    }

                    @media (min-width: 992px) and (max-width: 1199px) {
                        font-size: calc($f20 + 0.24rem);
                    }

                    @media (min-width: 1201px) and (max-width:1279px) {
                        font-size: calc(1.6rem + 0.075vw);
                    }

                    @media (min-width: 1280px) and (max-width:1400px) {
                        font-size: calc(1.6rem + 0.0175vw);
                    }

                    @media (min-width: 1401px) and (max-width:1440px) {
                        font-size: calc($f20 - 0.3rem);
                    }

                    @media (min-width: 1441px) and (max-width:1680px) {
                        font-size: calc($f20 - 0.2rem);
                    }

                    &:hover {
                        color: $primary-color;
                    }

                    &:focus-visible {
                        outline: 0;
                    }
                }

                .link_active {
                    color: $primary-color;
                    font-weight: $font-semibold;
                }

            }
        }
    }
}

.common_list_sidebar ul li span span svg,
.common_list_sidebar ul li a span svg {
    margin-right: 10px;
    transition: transform 1s;
    width: 11px;
}


@-webkit-keyframes pulse-grow-on-hover {
    0% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
        -webkit-animation-duration: 0.4s;
        animation-duration: 0.4s;
    }

    50% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        -webkit-animation-duration: 0.4s;
        animation-duration: 0.4s;
    }

    75% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        -webkit-animation-duration: 0.4s;
        animation-duration: 0.4s;
    }

    90% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
        -webkit-animation-duration: 0.4s;
        animation-duration: 0.4s;
    }

    100% {
        -webkit-transform: scale(1.4);
        transform: scale(1.4);
        -webkit-animation-duration: 0.4s;
        animation-duration: 0.4s;
    }
}

// @keyframes pulse-grow-on-hover {
//     to {
//         -webkit-transform: scale(1.3);
//         transform: scale(1.3);
//     }
// }


@keyframes pulse-grow-on-hover {

    0% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
        -webkit-animation-duration: 0.4s;
        animation-duration: 0.4s;
    }

    50% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        -webkit-animation-duration: 0.4s;
        animation-duration: 0.4s;
    }

    75% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        -webkit-animation-duration: 0.4s;
        animation-duration: 0.4s;
    }

    90% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
        -webkit-animation-duration: 0.4s;
        animation-duration: 0.4s;
    }

    100% {
        -webkit-transform: scale(1.4);
        transform: scale(1.4);
        -webkit-animation-duration: 0.4s;
        animation-duration: 0.4s;
    }

}

.pulse-grow-on-hover {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.common_list_sidebar ul li a:hover span.pulse-grow-on-hover,
.common_list_sidebar ul li a:hover span.pulse-grow-on-hover:focus,
.common_list_sidebar ul li a:hover span.pulse-grow-on-hover:active,
.common_list_sidebar ul li>span:hover span.pulse-grow-on-hover,
.common_list_sidebar ul li>span:hover span.pulse-grow-on-hover:focus,
.common_list_sidebar ul li>span:hover span.pulse-grow-on-hover:active {
    -webkit-animation-name: pulse-grow-on-hover;
    animation-name: pulse-grow-on-hover;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}

/* -----------------media begins-------- */

@media (max-width: 1200px) {
    .siderbar_common .desk-sidebar ul {
        height: calc(100vh - 120px);
        overflow-y: auto;
        padding: 0px 5px 0 0;
    }

    span.toggle_icon {
        display: block;
        position: relative;
        z-index: 1;
    }

    .sidenav .desk-sidebar {
        margin-top: 20px;
    }

    .desk-sidebar ul li a,
    .common_list_sidebar ul li a {
        margin: 5px 0 !important;
    }

    .common_list_sidebar .offcanvas-header-img img {
        max-width: 70px;
        position: relative;
        top: 0;
        left: 0%;
    }

    .common_list_sidebar ul li a.btn {
        min-height: 50px;
    }

    .siderbar_common>a.btn {
        width: 50px;
        margin-top: 25px;
        padding: 0;
    }

    /* -------toogle bar show css--- */
    .sidenav .closebtn {
        display: block;
        text-decoration: none;
        color: var(--base-color);
        border: 0px;
        background: transparent;
        outline: none;
        cursor: pointer;
    }

    .sidenav .closebtn img {
        max-width: 30px;
    }

    .siderbar_common .offcanvas.show {
        max-width: 85%;
    }

    .siderbar_common .offcanvas.show .offcanvas-header {
        justify-content: center;
        position: relative;
    }

    .siderbar_common .offcanvas-header-img {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        padding: 0;
    }

    .siderbar_common .offcanvas.show .offcanvas-header img {
        max-width: 80px;
    }

    .siderbar_common .offcanvas.show .offcanvas-header button.btn-close.text-reset {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .siderbar_common .offcanvas.show ul li .search_form {
        max-width: 283px;
        min-height: 35px;
        border-radius: 12.5px;
        border: 2px solid var(--grey-lighter);
    }

    .siderbar_common .offcanvas.show ul li .search_form input {
        min-height: 35px;
        border: none;
    }

    .siderbar_common .common_list_sidebar .desk-sidebar ul li.search-bar {
        display: flex !important;
    }

    .siderbar_common .desk-sidebar ul li.contact_link,
    .siderbar_common .desk-sidebar ul li.about_link,
    .siderbar_common .desk-sidebar ul li.list-item {
        display: block !important;
    }

    .siderbar_common .desk-sidebar ul li.about_link a {
        color: var(--base-color);
    }

    .common_list_sidebar ul li:nth-last-child(1) {
        border-bottom: 0px solid var(--grey-lighter);
    }

    .desk-sidebar li.search-bar .input-group {
        min-height: 40px;
        max-height: 40px;
        background: var(--light-grey);
        border-radius: 12.5px;
        margin-bottom: 5px;
    }

    .desk-sidebar li.search-bar .input-group input {
        border: none;
        border-radius: 12.5px;
        font-size: $f20;
    }

    .desk-sidebar li.search-bar .input-group .input-group-append .btn {
        padding: 5px 10px;
    }

    .desk-sidebar li.search-bar .input-group img {
        max-width: 20px;
        margin-top: 4px;
    }

    .desk-sidebar li.search-bar .input-group input:focus {
        box-shadow: none;
        background: var(--light-grey);
    }

    .desk-sidebar li.search-bar .input-group button svg {
        width: 15px;
    }

    .desk-sidebar li.search-bar .input-group button svg path {
        fill: var(--base-color);
    }

    .desk-sidebar li.search-bar .input-group input::placeholder {
        font-size: $f20;
    }
}

@media (max-width:767px) {


    .desk-sidebar ul li a,
    .common_list_sidebar ul li a {
        font-size: 1.1rem;
    }

    .common_list_sidebar ul li:nth-last-child(3) {
        border-bottom: 2px solid var(--grey-lighter);
    }
}

@media (min-width: 768px) and (max-width: 991px) {


    .desk-sidebar ul li a,
    .common_list_sidebar ul li a {
        font-size: 1.25rem;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {

    .siderbar_common ul li a,
    .common_list_sidebar ul li a {
        font-size: 1.15rem;
    }
}

@media (min-width: 1201px) and (max-width: 1300.98px) {
    .siderbar_common .offcanvas-header-img img {
        max-width: 120px;
    }

}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }

    .sidenav a {
        font-size: 18px;
    }
}