@import '../../../assets/css/base.scss';

.main_header {
    border-bottom: 2px solid var(--grey-lighter);
    padding: 20px 0;
    position: $absolute;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    max-width: 100%;

    @media (max-width: 1200px) {
        padding-left: 0;
        max-width: 100%;
        padding-right: 0;
    }

    @media (max-width: 767px) {
        padding: 7px 0;
    }

    @media (min-width: 768px) and (max-width:1199px) {
        padding: 10px 0;
    }

    @media (min-width: 1201px) and (max-width:1680px) {
        padding: 15px 0;
    }

    .btn {
        min-height: 46px;
        max-height: 46px;
        font-size: calc($f20 + 0.1vw);
        //max-width: 228px;
        // max-width: 180px;
        width: auto;
        text-transform: $uppercase;

        @media (min-width: 1201px) and (max-width:1400px) {
            height: 35px;
            min-height: 35px;
        }

        @media (min-width: 1201px) and (max-width:1279px) {
            font-size: calc($f20 - 0.57vw);
        }

        @media (min-width: 1280px) and (max-width:1400px) {
            font-size: calc($f20 - 0.4vw);
        }

        @media (min-width: 1401px) and (max-width:1440px) {
            height: 40px;
            min-height: 40px;
            font-size: calc($f20 - 0.2vw);
        }

        @media (min-width: 1441px) and (max-width:1680px) {
            height: 40px;
            min-height: 40px;
            font-size: calc($f20 - 0.13vw);
        }

        @media (min-width: 1900px) {
            font-size: calc($f20 + 0.2vw);
        }

        &.btn-primary {
            &:hover {
                background-color: var(--lighter-red);
                border-color: var(--lighter-red);
                color: var(--base-color);
            }
        }
    }

    .cstm_head {
            display: grid;
            align-items: center;
            grid-template-columns: auto 2fr;
        }
    

    .right_secn {
        ul {
            margin: 0;
            padding: 0;
            @include align-items ($center);

            li:first-child a {
                border-right: 2px solid var(--grey-lighter);
                padding-right: 10px;
                margin-right: 0px;
            }

            li:not(last-child) {
                margin-right: 10px;
            }

            li:last-child {
                margin-right: 0px;
            }
        }

        .common-link {
            background-color: $transparent;
            border: 0px;
            color: var(--base-color);
            font-size: calc($f20 + 0.3vw);
            text-decoration: $none;
            text-transform: $uppercase;
            @include flexbox;
            @include align-items(center);
            line-height: 1;

            @media (max-width:767px) {
                @include font-size(1.6);
            }

            @media (max-width:575.98px) {
                @include font-size(1.4);
            }

            @media (min-width: 768px) and (max-width:991px) {
                font-size: calc($f20 - 0.2vw)
            }

            @media (min-width:992px) and (max-width:1199px) {
                font-size: calc($f20 - 0.2vw);
            }

            @media (min-width: 1201px) and (max-width:1279px) {
                font-size: calc($f20 - 0.57vw);
            }

            @media (min-width: 1280px) and (max-width:1400px) {
                font-size: calc($f20 - 0.35vw);
            }

            @media (min-width: 1401px) and (max-width:1440px) {
                font-size: calc($f20 - 0.1vw);
            }

            @media (min-width: 1441px) and (max-width:1680px) {
                font-size: calc($f20 - 0.1vw);
            }
        }
    }

    // header search form
    .search_form {
        max-width: 220px;
        min-height: 46px;
        max-height: 46px;
        @include border-radius(12.5px);
        border: 2px solid var(--grey-lighter);
        position: $relative;
        @include flexbox;
        @include align-items(center);

        @media (min-width: 1201px) and (max-width:1400px) {
            min-height: 35px;
            max-height: 35px;
        }

        @media (min-width: 1401px) and (max-width:1680px) {
            min-height: 40px;
            max-height: 40px;
        }

        @media (min-width: 1201px) and (max-width:1440px) {
            max-width: 145px;
        }

        @media (min-width: 1440px) {
            max-width: 180px;
        }

        @media (min-width: 1680px) {
            max-width: 220px;
        }

        input {
            border: $none;
            border-radius: 12.5px;
            @include font-size(1.6);
            color: var(--base-color);
            background: transparent;
            min-height: calc(100% - 0px);
            padding: 0.5rem 0.75rem;
            line-height: 1.5;
            padding-right: 30px;
            width: 100%;

            &:focus {
                background-color: transparent !important;
                outline: 0;
            }

            @media (min-width: 1280px) {
                @include font-size(1.7);
                line-height: 1.4;
            }

            @media (min-width: 1440px) {
                @include font-size(2);
                line-height: 1.5;
            }

            @media (min-width: 1680px) {
                @include font-size(2.2);
            }
        }

        &_btn {
            border: $none;
            background-color: $transparent;
            position: $absolute !important;
            right: 10px;
            top: 50%;
            padding: 5px;
            @include transform(translateY(-50%));
            @include flexCenter;
            padding: 0 !important;
            height: 100% !important;
            min-height: 100% !important;

            img {
                max-width: 20px;
                width: 20px;
                @media (max-width:1280px) {
                    max-width: 16px;
                    width: 16px;
                }
            }
        }
    }

    .header_right {
        .sign-in-user {
            span {
                text-overflow: ellipsis;
                overflow: $hidden;
                white-space: nowrap;
                display: $inline-block;
                line-height: 1.3;

                @media (max-width:1440px) {
                    max-width: 80px
                }

                @media (max-width:575.98px) {
                    max-width: 70px
                }

                @media (min-width:1440px) {
                    max-width: 150px
                }
            }
        }

        .signout {
            img {
                @media (max-width:575.98px) {
                    max-width: 20px;
                }
            }
        }
    }

   
    // ends search form header
}

// css for top wrapper for header----
.top-wrapper {
    max-width: 97%;
    padding: 86px 0px 0 0;
    margin-right: $auto;
    position: $relative;
    width: 100%;

    @media (max-width: 1200px) {
        padding-left: 0;
        max-width: 100%;
        padding-right: 0;
    }

    @media (min-width:768px) and (max-width: 1199px) {
        padding-top: 80px;
    }

    @media (min-width:1201px) and (max-width: 1680px) {
        max-width: 98%;
    }

    @media (min-width:1201px) and (max-width: 1536px) {
        padding: 70px 0px 0 0;
    }

}

// ends top wrapper
.main_wrapper {
    @media (min-width:1200px) {
        max-width: 97%;
    }
}

// two new button with images
.cstm_btn {
    @include align-items(center);

    img {
        max-width: 30px;
        max-height: 30px;
        margin-right: 5px;
    }
}



.main_header .right_secn ul li.about_link .btn:hover {
    border-color: var(--sky-blue-dark100);
}

// for sign and signout button a link css

.right_secn {
    ul {
        li.sign-in-user {
            img {
                max-width: 25px;
                height: auto;
                width: 100%;
                margin-right: 10px;
                margin-top: 0px;

                @media (max-width:575.98px) {
                    margin-right: 5px;
                }
            }

            a {
                @include flexbox;
                @include align-items(center);

                &:hover {
                    color: var(--lighter-red);
                }
            }
        }

        li.signup-user {
            a {
                &:hover {
                    color: var(--lighter-red);
                }
            }
        }
    }
}

.sidebar-main svg {
    max-width: 25px;
    height: 25px;
    width: 100%;
}

.toggle_icon {
    display: none;
}


/* -----media begins--------- */

@media (max-width: 1200px) {
    .main_header {
        max-width: 100%;
        position: $fixed;
        background: var(--white);

        .row.cstm_head {
            grid-template-columns: auto 1fr;
            width: 100%;
            margin: 0;

            a.btn.btn-primary {
                display: none !important;
            }
        }

        .right_secn ul.header_right_list {
            margin-right: 0px;
        }

        .left_secn {
            @include justify-content(space-between);
            @include flex-direction(row);
            width: auto;
            span {
                img {
                    padding: 0 !important;
                    max-width: 30px;
                }

            }

            .navbar-brand {
                margin: 0;
                display: $block !important;

                img {
                    max-width: 60px;
                    margin: 0;
                    display: $block;
                }
            }
        }

        .right_secn {
            justify-content: flex-end !important;

            button.toggle_icon {
                padding: 0 !important;
                border: none;
                background: transparent;
                display: $block;

                img {
                    padding: 0 !important;
                    max-width: 25px;
                    height: auto;
                }
            }

            li.search-bar,
            li.contact_link,
            li.about_link {
                display: none;
            }
        }
    }

    .sidebar-main .common_list_sidebar {
        padding: 15px;
    }

    .main_header .right_secn ul li a.sign-in svg {
        width: 16px;
    }

}



@media (max-width: 575.98px) {
    .main_header .right_secn ul li.sign-in-user a {
        display: flex;
        align-items: center;
        line-height: normal;
    }

    .main_header .right_secn ul li.sign-in-user a img {
        margin-top: 0;
        width: 17px;
    }
    
}
@media (max-width: 360px) {
.main_header{
    .left_secn {
        padding: 0;
    }
}
}
@media (max-width: 767px) {
    .top-wrapper {
        padding-top: 73px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .main_header .right_secn ul li a.sign-in svg {
        width: 20px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {

    .main_header .right_secn ul li.sign-in-user a {
        display: flex;
        align-items: center;
        min-height: auto;
    }

    .main_header .right_secn ul li.sign-in-user img {
        max-width: 20px;
        height: auto;
    }
}

// custom edited
@media (min-width: 1200px) and (max-width: 1279px) {
    .main_header .row.cstm_head {
        grid-template-columns: auto 2fr;
    }

    .main_header .input-group-append .btn {
        min-height: 100%;
        max-height: fit-content;
        height: auto;
    }


    .main_header .right_secn ul li.sign-in-user img {
        max-width: 20px;
        height: auto;
        width: 100%;
    }
}

@media (min-width: 1280px) and (max-width: 1400px) {
    .main_header .row.cstm_head {
        grid-template-columns: auto 2fr;
    }


    .main_header .right_secn ul li.sign-in-user img {
        max-width: 20px;
        height: auto;
        width: 100%;
    }

}

@media (min-width: 1401px) and (max-width: 1680px) {

    .main_header .row.cstm_head {
        grid-template-columns: auto 2fr;
    }


    .main_header .right_secn ul li.sign-in-user img {
        max-width: 20px;
        height: auto;
        width: 100%;
    }

}

// ends---
@media (min-width: 1200px) and (max-width: 1249px) {
    .main_header .right_secn ul li.sign-in-user img {
        margin-top: 0;
    }

    .main_header .right_secn ul li:not(last-child) {
        margin-right: 8px;
    }
}

@media (min-width: 1250px) and (max-width: 1439.98px) {


    .right_secn ul li.sign-in-user img {
        max-width: 18px;
        height: auto;
        width: 100%;
    }

    .right_secn ul li.sign-in-user img {
        margin-top: 0;
    }

}



@media (min-width: 1500px) and (max-width: 1549px) {

    .main_header .right_secn ul li:not(last-child) {
        margin-right: 9px;
    }

    .main_header .right_secn ul li .search_form svg,
    .main_header .right_secn ul li.sign-in-user svg {
        max-width: 15px;
        height: 15px;
        width: 100%;
    }

}

@media (min-width: 1550px) and (max-width: 1599px) {


    .main_header .right_secn ul li.sign-in-user img {
        max-width: 22px;
        height: auto;
        width: 100%;
    }

    .main_header .right_secn ul li.sign-in-user img {
        margin-top: 0;
    }


}

@media (min-width: 1681px) and (max-width: 1799px) {
    .main_header .row.cstm_head {
        grid-template-columns: auto 2fr;
    }

}