@import 'assets/css/base.scss';
@import './assets/css/theme.scss';
@import './assets/css/map.scss';
@import './assets/css/chat-forum.scss';
@import 'react-tabs/style/react-tabs.scss';
@import './Views/Authentication/MentalHealthCategories/style.scss';
@import './Components/atoms/CustomSnackBar/style.scss';
@import './Views/Authentication/LandingPage/landing.scss';
@import './Components/atoms/MentalHealthNavbar/style.scss';
@import './Components/atoms/TablePagination/style.scss';
@import './Views/Authentication/Checklist/style.scss';
@import './Views/Authentication/SupportGroups/style.scss';
@import './Views/Authentication/Login/style.scss';
@import './Views/Authentication/EditProfile/style.scss';


@import '../node_modules/react-paginate/theme/basic/react-paginate.css';
// @import 'react-tabs/style/react-tabs.scss';
 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tooltipstyles {
  background: rgb(238 83 115);
  color: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 5px;
  display: inline-block;
  min-width: 90px;
  text-align: center;
  z-index: 1000;
  font-size: 1.2rem;
  max-width: 250px;
  overflow: auto;
  word-break: word-break;
}

.article-title {
  h1,
  .h2 {
    word-break: break-word;
    overflow-wrap: break-word;
    text-transform: capitalize;
  }
}

.rbc-event-label {
  position: $absolute;
  top: 3px;
  left: 3px;
}

.rbc-event-content {
  margin-top: 3px !important;
}

// .tooltipstyles::after {
//   position: absolute;
//   top: 100%;
//   left: 50%;
//   content: '';
//   width: 0;
//   height: 0;
//   border-left: 7px solid transparent;
//   border-right: 7px solid transparent;
//   border-top: 7px solid #2a629f;
//   transform: translateX(-50%);
// }

.btn-zoom {
  width: 35px;
  height: 35px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  svg {
    font-size: 28px;
  }

  background: rgb(234 234 234) !important;
  color: rgb(98 98 98) !important;
  border-radius: 50% !important;
  padding: 20px !important;
  box-shadow: 3px 2px 1px rgb(218 218 218);
}

.not-available-text {
  display: block;
  text-align: center;
  margin-top: 20px;
}

.resource-for-you {
  .checklist-block {
    .lg-pink-block {
      img {
        min-height: 0px !important;
      }
    }
  }
}

.tracker-datepicker {
  .MuiFormControl-root {
    .MuiInputBase-formControl {
      background: var(--light-white);
      min-height: 35px;
      max-height: 35px;
      border-radius: 12.5px;
      @media (min-width:1200px) {
        min-height: calc(1.5em + 2rem - 7.5px);
        max-height: 45px;
      }
      fieldset {
        display: $none;
      }
    }
  }
  >div {
    width: 100%;
  }
  div {
    font-size: 1.5rem !important;
  }
  fieldset {
    legend {
      display: none;
    }
  }
}

.Modal.calendar_modal {
  max-width: 500px;
}

.Modal.calendar_modal.free_text_modal {
  max-width: 690px !important;
}

.event_calendar_time_picker {

  >div {
    width: 240px;
  }

  svg {
    width: 1.9rem;
    height: 1.9rem;
  }

  input {
    font-size: 1.35rem;
  }
}


.event_calendar_time_picker_tracker {
  >div {
    width: 100%;
  }

  svg {
    width: 2.3rem;
    height: 2.3rem;
  }

}

.tracker-bottom-bar {
  flex: 1 !important;
}

.text_editor {
  .rdw-dropdownoption-default {
    &.rdw-inline-dropdownoption {
      height: 25px;

      img {
        margin: 5px 0px;
        max-width: 25px;
        max-height: 25px;
      }
    }

    &.rdw-list-dropdownOption {
      height: 30px;

      img {
        margin: 5px 0px;
        max-width: 30px;
        max-height: 30px;
      }
    }

    &.rdw-text-align-dropdownOption {
      height: 30px;

      img {
        margin: 5px 0px;
        max-width: 30px;
        max-height: 30px;
      }
    }

    &.rdw-history-dropdownoption {
      height: 32px;

      img {
        margin: 5px 0px;
        max-width: 32px;
        max-height: 32px;
      }
    }
  }

  .demo-editor {
    &.rdw-editor-main {
      word-break: word-break;
    }
  }
}

.hypernode_fields {
  .radio_label {
    flex: 1 1 50% !important;

    label {
      width: 100% !important;
    }
  }
}


// error css during login
.error {
	color: var(--dark-red);
	font-size: $f16;
	position: relative;
	text-align: left;

	@media (max-width: 767px) {
		font-size: $f16 - 0.2;
	}

	@media (min-width: 768px) and (max-width: 991px) {
		font-size: $f16 - 0.2;
	}

	@media (min-width: 992px) and (max-width: 1199px) {
		font-size: $f16 - 0.2;
	}

	@media (min-width: 1200px) and (max-width: 1279px) {
		font-size: $f16 - 0.4;
	}

	@media (min-width: 1280px) and (max-width: 1400px) {
		font-size: $f16 - 0.4;
	}

	@media (min-width: 1401px) and (max-width: 1680px) {
		font-size: $f16 - 0.2;
	}
}

.btn_setting {
  @media (max-width:767px) {
    overflow-x: auto;
  }
}

.rdw-text-align-dropdown {
  z-index: 0 !important;
}

.tracker_form_footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}


.updateBtn a {
  text-transform: inherit;
}

a.btn.sky-blue-btn.btn-md,
button.btn.sky-blue-btn.btn-md,
a.btn.btn-primary.btn-md,
button.btn.btn-primary.btn-md {
  text-transform: capitalize;
}

.SnackbarItem-action {
  button {
    background-color: $white-color !important;
    @include border-radius(50%);
    width: 25px; 
      height:25px;
      min-width: 25px;
      min-height: 25px;
      font-weight:bold;
      padding: 5px;
    svg {     
      width: 20px; 
      height:20px; 
      color: $white-color;   
      path {
        fill:var(--light-red);
      }  
      
    }
    &:hover {
      background-color:$white-color !important;
      svg {
        path{

        }
      }
    }
  }
}

.community-actions {
  span {
    cursor: pointer;
      &.edit_acn {
          color: green;           
      }
      &.delete_acn {
          color: red;         
      }
  }
}

.cstm_Datetime-picker {
  z-index: 30000 !important;
}

.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    @include font-size(1.4);
  }
}
.no-wrap-spaces {
  white-space: nowrap;
}

.newTitle h2 {
  border-bottom: 2px solid #000;
}


.all_cat_dropdown {
  min-width: fit-content;
  max-width: 150px;
  width: 100%;

  @media (min-width:1200px) {
      min-width: 210px;
      max-width: 210px;
  }

  @media (min-width:768px) and (max-width:1199px) {
      min-width: 200px; max-width: 200px;
  }

  @media (max-width:575px) {
      min-width: 100%;
  }

  @media (min-width:576px) and (max-width:767px) {
    min-width: auto; max-width: 200px;
  }

  .react-select-container {
      .react-select__control {
          min-height: 40px;
          @media (min-width:1200px) {
              min-height: 42px;
          }
          @media (min-width:768px) and (max-width:1199px) {
              margin-bottom: 0px;
          }
      }

  }


}

.control-container {
  position: relative;
}

.verticle-slider-container {
  position: relative;
}

.verticle-slider {
    position: absolute;
    rotate: 90deg;
    -webkit-transform: translateY(92px) translateX(-68%);
    transform: 0;
    padding: 10px;
    background: #ffffff;
    border-radius: 10px;
    z-index: 1;
    top: 238px;
    left: 15px;
}

.verticle-slider-show {
  display: block;
}
.text-transform-none {
  text-transform: none !important;
}

.f-1p8 {
  font-size: 1.8rem;
}

.bold_label {
  font-weight: 900;
  display: inline;
}

.social-icon-group {
  li {
    cursor: pointer;
  }
}

.error-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}


.rangeslider-horizontal .rangeslider__fill{
  background-color: #3aa3e5;
}

.rangeslider-horizontal {
  height: 5px;
}
.rangeslider-horizontal .rangeslider__handle:after{
  display: none;
}
.rangeslider-horizontal .rangeslider__handle{
  width: 15px;
  height: 15px;
}
.video-player_videoEl__1OwoK {
  position: static;
}
.video_node_secn .ratio::before {
  padding-top: 0;
}
.player-controls {
  top: 0;
}
.video-player_seekbarClass__lvhSm {
  top: 0;

}
.desktop-toolbar {
  position: absolute;
  bottom: 0;
  width: 100%;
}desktop-toolbar {
  position: absolute;
  bottom: 0;
}


.volume_slider {
  .rangeslider.rangeslider-horizontal {
    width: 150px;
    @media (max-width:576px) {
      width: 120px;
    }
  }
}

.Video_player_column {
  video {
    background-color: #000;
    width: 100%;
    min-height: 200px !important;
  }
}

.search-result-section .video-title {
  display: flex;
  justify-content:space-between;
  width: 200px;
}