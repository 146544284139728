// _color.scss

:root {
    --base-color: #463758;
    --base-color-light:#544765;
    --black: #000;
    --black-light-500: #292a2f;

    --red: #CF1B1B;
    --dark-red: #f53e3f;
    --lighter-red: #eca7ab;
    --lighter-red1: #ffb1b6;
    --lighter-white: #e8fefe;
    --dark-pink: #f94f84;
    --light-pink: #ffb2b6;
    --lighter-pink: #ffe7ed;
    --light-red: #ff5782;
    --light-orange: #E3D1C4;
    
    /* --white---- */
    --white: #ffffff;
    --white-100: #fdfdfd;
    --light-white: #ecf0f0;
    --cream: #F6F1ED;
    --grey: #cccccc;
    --light-grey: #f2f2f3;
    --grey-lighter: #d5dfe8;
    --grey-medium-light: #6c6c6c;
    --grey-b3: #b3b3b3;
    --grey-light-border: #cdcad0;
    --grey-f4: #f4f4f4;
    --disabled-color:#e9ecef;
    --grey-b9:#b9b9b9;
    /* ------blue-variants-sky--- */
    --sky-blue: #43C7E7;
    --sky-blue2: #00beed;
    --sky-blue3: #00beec;
    --sky-blue-dark: #0092d8;
    --primary-color: #0092d8;
    --sky-blue-dark100: #0085cc;
    --sky-blue-lighter1: #daffff;
    --sky-blue-lighter2: #90d9d7;
    --light-sky:#f1f8ff;
    --light-sky-blue: #00f3f4;
    --light-dark-blue: #00327d;
    --light-blue: #C6D9EF;
    --light-blue1: #D7E4F3;
    --light-blue-100: #f4f7fe;

    --pink-100:#f8e0e2;
}

$base-color: #463758;
$primary-color: #0092d8;
$light-pink: #eca7ab;
$lighter-pink: #ffe7ed;
$light-grey:#f2f2f3;
$lighter-red1: #ffb1b6;
$grey: #cccccc;
$grey-100: #d5dfe8;
$grey-500: #d1d1d3;

$pink-100:#f8e0e2;

$white-color: #ffffff;
$black-color: #000000;
$green-color: #0EA12F;
$green-color-600: #146c43;
$red-color: #F62727;

$transparent: transparent;

$error-color: var(--error-color);