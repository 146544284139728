// @import '../../../../assets/css/base.scss';
@import '../../../assets/css/base.scss';

.download_font {
    h1 , label {
        font-family:'Arial' !important;
    }
}
.trackers_quesns {
    margin:30px 0px;
    
    .download {
        position: absolute;
        left: 5000px;
        width: 672px;
    }
}
.save-quesns {
    button:not(:last-child) {
        margin-right: 10px;
        @media (max-width:575px) {
            margin-right: 0;
        }
    }
}
.quesn_accordion {
    border: 1px solid var(--dark-pink);
    padding: 5px;
    @include border-radius(10px);


    .quesn_accordion_title {
        background:var(--light-white);
        padding:10px;
        @include border-radius(10px);
      
        h3 {
            margin:0;
            font-weight: 500;            
            @include flexbox;
            @include justify-content(space-between);
            @include align-items(center);
            cursor: $pointer;
            @include transition(all, 0.3s, ease-in-out);
            svg {
                font-size: $f30;
            }
        }
    }
    .accordion_body {
        padding:20px;
        @include transition(all, 0.3s, ease-in-out);
    }
}