@import "../../../assets/css/base.scss";

.card {
 &.sub_card_box {
    &:not(:last-child) {
        margin-bottom: 20px;
    }
    &:last-child {
        margin-bottom: 0px !important;
    }
}
}