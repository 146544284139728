

.chat_forum {
    margin-top: 15px;
    padding-top:50px;
    padding-bottom: 50px;
    background: #f7f7f7;   
    @media (max-width:1366px) {
        padding-left:10px;
        padding-right:10px;
    } 
    @media (max-width:575px) {
        padding:0;
    } 
}

    .card_box {
        background-color: var(--white) !important;
        @include transition(all, 0.5s, ease-in-out);
        @include border-radius(10px , true);
        // box-shadow: 0px 3px 10px #cccccc52;
        border-color: #ebebeb !important;
        -webkit-box-shadow: 5px 5px 20px 0px rgb(117 117 117 / 10%);
        box-shadow: 5px 5px 20px 0px rgb(117 117 117 / 10%);
        &:hover {
            border-color:var(--base-color);
        }
        p, .post_content {
            text-align: $left;
            margin: 0 0 15px 0;
            font-size: $f16;
            line-height: 25px;
            color: #666e82;
            // text-transform: $capitalize;
        }

        .postFoot {
            @include flexbox;
            @include justify-content (flex-start);
            @include align-items (center);
            font-size: 1.5rem;
            margin-bottom: 0px;
            @include border-radius(5px);
            overflow: auto;
            @media screen  and (max-width:575px){
                @include flex-wrap (wrap);
            }
            .icon_box {
                padding:6px 5px;
                margin-right: 5px;
                @include border-radius(4px);
                display: $inline-block;
                white-space: nowrap;
                line-height: 20px;
                font-size: $f14;
                @include transition(all, 0.5s, ease-in-out);
                background:#f3f3f3;
                @media (max-width:767px) {
                    margin-bottom: 5px;
                }
              
                svg {
                    width: 20px;
                    height: 20px;
                    margin-right: 2px;
                    @include transition(all, 0.5s, ease-in-out);

                    path {
                        fill: var(--grey-b3);
                    }
                }
                &.active {
                    svg {
                        path {
                            fill:var(--sky-blue3);
                        }
                    }
                    color:#666e82;
                }
                &:hover {
                    color:var(--base-color);
                    background-color: var(--light-sky);
                    svg {
                        path {
                            fill: var(--base-color);
                        }
                    }
                }
                
            }
        }
    
        .card-body {
            padding: 3rem!important;
            color: #666e82;
            @media screen and (max-width:767px) {
                padding:1.25rem !important
            }
            .post_sub_title {
                text-transform: capitalize;
                border-bottom: 1px solid #ebebeb;
                padding-bottom: 10px;
                margin-bottom: 10px;
                display:block;
                a {
                    color: #0048cc;
                    &:hover {
                        color: #0255ee;
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
              }
            .reply_thread {
                .post_content {
                    color: var(--base-color);
                }
            }
        }
      
    }


.post_title {
        cursor: pointer;
        overflow: $hidden;
        text-overflow: ellipsis;
        // white-space: nowrap;
        margin-bottom: 15px !important;    
        text-transform: $capitalize;
        &:hover {
            color:#0088d0;

        }
    }


.marginD {
    margin: 20px;
}

.paddingRight {
    padding-right: 15px;
    cursor: pointer;
}


.alignIcon {
    @include flexbox;
    @include justify-content (center);
    @include align-items (center);
   
}

// .makePost {
//     font-size: 1rem;
//     cursor: pointer;
//     width: 75%;
//     margin: auto;
//     background-clip: border-box;
//     border: 1px solid rgba(0,0,0,.125);
// }

.readmore {
    color: rgb(0, 191, 255);
    text-decoration: underline;
    cursor: pointer;
}