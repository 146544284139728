@import '../../../assets/css/base.scss';

.cstm_Date_picker {
    min-height: 50px;

    @media screen and (max-width:1280px) {
        min-height: 35px;
        max-height: 35px;
    }

    label {
        font-size: $f16 !important;
        margin-bottom: 0 !important;
        line-height: 22px;
        font-weight: $font-regular !important;

        @media screen and (max-width:1280px) {
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
        }
    }

    .MuiInputBase-formControl {
        min-height: 50px;
        max-height: 50px;
        // background: var(--light-white);
        border: none;
        width: 100%;
        border-radius: 12.5px;
        color: var(--base-color);
        font-size: 2rem;
        font-weight: 300;
        outline: 0 !important;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        padding: 0.5rem 1rem;

        @media screen and (max-width:1280px) {
            min-height: 35px;
            max-height: 35px;
        }

        .MuiOutlinedInput-input {
            min-height: calc(1.15em + 1rem + 2px);
            padding: 0.5rem 1rem;
            // font-size: $f16;
            font-weight: 600;
        }

        .MuiOutlinedInput-notchedOutline {
            max-height: 50px;
            border-color: transparent !important;
            top: 0;

            @media screen and (max-width:1280px) {
                max-height: 35px;
            }
        }

        .MuiInputAdornment-root {
            margin-right: 8px;

            button {
                background: rgba(0, 0, 0, 0.08);

                svg {
                    width: 3rem;
                    height: 3rem;
                }
            }
        }


    }
}

.cstm_Date_picker {
    .MuiPaper-root {
        .MuiCalendarOrClockPicker-root {
            .MuiCalendarPicker-root {
                &>div {
                    font-size: $f16;
                }
            }
        }
        .MuiCalendarPicker-root {
            &>div {
                font-size: $f16;
            }

            .PrivatePickersFadeTransitionGroup-root {
                div {
                    font-size: $f16 ;
                }
            }

            .MuiPickersArrowSwitcher-root {
                .MuiPickersArrowSwitcher-button {
                    svg {
                        width: 2rem;
                        height: 2rem;
                    }
                }
            }

            .PrivatePickersFadeTransitionGroup-root {
                .MuiYearPicker-root {
                    .PrivatePickersYear-root {
                        button {
                            &.PrivatePickersYear-yearButton {
                                font-size: $f16;
                            }
                        }
                    }
                }
            }
        }

        .MuiPickersArrowSwitcher-root {
            .MuiPickersArrowSwitcher-button {
                svg {
                    width: 2rem;
                    height: 2rem;
                    font-size: $f16;
                }
            }
        }

        .MuiCalendarPicker-viewTransitionContainer {
            div[role="grid"] {
                div[role="row"] {
                    span {
                        font-size: $f16;
                    }
                }
            }

            .PrivatePickersSlideTransition-root {

                div[role="row"] {
                    .MuiPickersDay-root {
                        font-size: $f14;
                    }
                }

            }
        }
    }
}