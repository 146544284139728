@import "../../../assets/css/base.scss";

.mobile-toolbar {
    opacity: 0;
    position: $absolute;
    top: 0;
    width: 100%;
    z-index: 2;
    @media (min-width:768px) {
        display: $none;
    }
    .player-controls {
        top: 0;
        background: rgba(0,0,0,0.5);
    }
}

.desktop-toolbar {
    @media (max-width:767px) {
        display: $none;
    }
}

.playNextIcons {
    opacity: 0;
    @media (min-width:768px) {
display: $none;
    }
    @media (max-width:767px) {
        position: $absolute;
        top: 50%;
        left: 0;
        z-index: 1;
        width: 100%;
        @include flexbox;
        @include align-items(center);
        @include justify-content(space-evenly);
    }
    svg {
        width: 30px;
        height: 30px;
        path {
            fill:$white-color;
        }
    }

}

.mobile-video-duration {
    position: $absolute;
    bottom: -7px;
    top: auto;
    width: 100%;
    z-index: 1;
    @media (min-width:768px) {
        display: $none;
            }
}

@media (max-width:767px) {
    .mobile-video-duration {
        bottom: 3px !important;
    }
}