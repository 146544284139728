@import '../../../assets/css/base.scss';



    .cstm_time-picker {
        input {
            min-height: 42px !important;
            @include border-radius(12.5px);
            @include font-size(1.6);
            background: var(--light-white);
            border: $none;
            @media (min-width:992px) {
                @include font-size(1.8) ;
            }
            &:active,&:focus {
                border-color: transparent; 
                background: var(--light-white);
                outline: 0;
            }
        }
    }


.cstm_time-picker { 
    min-height: 50px;

    @media screen and (max-width:1280px) {
        min-height: 35px;
        max-height: 35px;
    }

    label {
        font-size: $f16 !important;
        margin-bottom: 0 !important;
        line-height: 22px;
        font-weight: $font-regular !important;

        @media screen and (max-width:1280px) {
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
        }
    }

    .MuiInputBase-formControl {
        min-height: 50px;
        max-height: 50px;
        background: var(--light-white);
        border: none;
        width: 100%;
        border-radius: 12.5px;
        color: var(--base-color);
        font-size: $f16;
        font-weight: 300;
        outline: 0 !important;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        padding: 0.5rem 1rem;

        @media screen and (max-width:1280px) {
            min-height: 35px;
            max-height: 35px;
        }

        .MuiOutlinedInput-input {
            min-height: calc(1.15em + 1rem + 2px);
            padding: 0.5rem 1rem;
            font-size: $f16;
        }

        .MuiOutlinedInput-notchedOutline {
            max-height: 50px;
            border-color: transparent !important;
            top: 0;

            @media screen and (max-width:1280px) {
                max-height: 35px;
            }
        }

        .MuiInputAdornment-root {
            margin-right: 8px;
        }

        &.Mui-disabled {
            background: var(--disabled-color);
        }

        .MuiIconButton-root {
            background: #e1e1e1;

            @media screen and (max-width:1280px) {
                font-size: $f12;
            }

            svg {
                @media screen and (max-width:1280px) {
                    font-size: $f12;
                }
            }
        }
    }
}

// css for calender popup time watch
.common_time_picker {
    z-index: 999999 !important;

    .MuiClockPicker-root {
        .css-fhpqww {
            font-size: $f14;
        }

        .MuiIconButton-root {
            span {
                font-size: $f14;
            }
        }

        .MuiClockPicker-arrowSwitcher {
            button.MuiPickersArrowSwitcher-button {
                svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}

.common_time_picker_mobile {
    z-index: 9999999 !important;

    .MuiDialog-container {
        .MuiPaper-root {
            
        .MuiDialogContent-root {
            .MuiPickersToolbar-root {
                .MuiTypography-root {

                    font-size: $f16;

                    @media screen and (max-width:1200px) {
                        font-size: $f14;
                    }
                }

                .MuiGrid-root {
                    @include align-items(center);
                    border-bottom: 1px solid var(--grey);
                    padding-bottom: 10px;
                }
            }
          
            .MuiCalendarPicker-root {
                div[role="presentation"] {
                    .PrivatePickersFadeTransitionGroup-root {
                        div {
                            font-size: $f14;
                        }
                    }
                   
                }
                .MuiPickersArrowSwitcher-root {
                    button {
                        svg {
                            width: 1.5rem;
                            height: 1.5rem;
                        }
                    } 
                }
                
                .MuiCalendarPicker-viewTransitionContainer {
                    div[role="grid"] {
                        div[role="row"] {
                            .MuiPickersDay-root {
                                font-size: $f14;
                            }
                            .MuiTypography-caption {
                                font-size: $f14;
                            }
                        }
                    }
                    div {
                        font-size: $f14;
                        .MuiButtonBase-root {
                           font-size: $f14; 
                        }
                        div[role="row"] {
                            .MuiPickersDay-root {
                                font-size: $f14;
                            }
                        }

                        .PrivatePickersSlideTransition-root {
                           div {
                                font-size: $f14;
                            
                           }
                        }
                    }
                }
            }
        
        }

        .MuiDialogActions-root {
            .MuiButton-textSizeMedium {
                @media screen and (max-width:1280px) {
                    font-size: $f14;
                }
            }

            .MuiPickersToolbar-penIconButton {
                svg {
                    @media screen and (max-width:1280px) {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
    }

}