@import '../../../assets/css/base.scss';

.event_date_picker {
    .MuiPickerStaticWrapper-root {
        .MuiPickerStaticWrapper-content {
            background: var(--white);
            // background: var(--lighter-pink);
            @include border-radius(7px 7px 0 0);
            min-width: 270px;
            max-width: 270px;
            padding: 0 5px;

            @media (max-width:767px) {
                min-width: 100%;
                max-width: 100%;
            }

            .MuiPickersCalendarHeader-root {
                >div {
                    font-size: 1.6rem !important;
                }
            }

            div {
                width: 100%;
            }

            div.MuiPickersArrowSwitcher-root {
                width: 50px;
            }

            div.MuiPickersArrowSwitcher-spacer {
                width: 0px;
            }

            .MuiCalendarPicker-root {
                &>div {
                    @include font-size(1.6);
                    // padding: 0px 8px;
                }

                &>:first-child {
                    >div {
                        width: auto;
                    }

                    margin-top: 0;
                    padding: 0px 8px;
                }

                .PrivatePickersFadeTransitionGroup-root {
                    div {
                        @include font-size(1.5);
                    }
                }

                .MuiPickersFadeTransitionGroup-root {
                    div {
                        @include font-size(1.5);
                    }
                }

                div[role="presentation"] {
                    div {
                        @include font-size(1.5);
                    }
                }

                .MuiYearPicker-root {
                    .PrivatePickersYear-root {
                        button {
                            &.PrivatePickersYear-yearButton {
                                @include font-size(1.5);
                            }
                        }
                    }
                }
            }

            .MuiPickersArrowSwitcher-root {
                .MuiPickersArrowSwitcher-button {
                    font-size: $f12 !important;

                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }
            }

            div[role="grid"] {
                div[role="row"] {
                    span {
                        &[role="columnheader"] {
                            @include font-size(1.5);
                        }

                        &.MuiTypography-caption {
                            @include font-size(1.5);
                        }
                    }
                }

                .PrivatePickersSlideTransition-root {
                    div[role="rowgroup"] {
                        div[role="row"] {
                            .MuiPickersDay-root {
                                @include font-size(1.3);
                                // width: 25px;
                                // height: 25px;
                                // line-height: 1.3;

                                // @media (max-width:991px) {
                                //     font-size: 16px;
                                // }

                            }
                            button {
                                &.MuiPickersDay-root {
                                      @include font-size(1.3);
                                }
                            }
                        }
                    }
                   
                }
            }

            .MuiCalendarPicker-viewTransitionContainer {
                .PrivatePickersYear-root {
                    button {
                        @include font-size(1.6);
                    }
                }



                .PrivatePickersSlideTransition-root {
                    div[role="row"] {
                        .MuiPickersDay-root {
                            @include font-size(1.4);
                            width: 25px;
                            height: 25px;
                            line-height: 1.3;

                            @media (max-width:991px) {
                                font-size: 16px;
                            }
                        }
                    }

                }
            }

        }
    }
  
}

// html body #root .event_date_picker .MuiPickerStaticWrapper-root .MuiPickerStaticWrapper-content .MuiCalendarPicker-viewTransitionContainer .PrivatePickersSlideTransition-root div[role=row] .MuiPickersDay-root {
//     font-size: 16px !important; 
// }


// .rbc-current-time-indicator {
//     display: none !important;
// }

.calender_filter {
    &-column {
        .common_chip {
            // height: 26px;
            color: var(--white);
            font-weight: $font-medium;
            @include font-size(1.2);
            margin-right: 5px;
            flex: 1 1 auto;
            min-width: 60px;

            &:last-child {
                margin-right: 0;
            }
        }

        .MuiAccordion-root {
            box-shadow: $none;

            .MuiAccordionSummary-root {
                min-height: auto !important;

                .MuiAccordionSummary-content {
                    margin: 8px 0;

                    p {
                        @include font-size(1.6);
                    }
                }

                .MuiAccordionSummary-expandIconWrapper {
                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }

                &.Mui-expanded {
                    border-bottom: 1px solid var(--grey);

                    .MuiAccordionSummary-content {
                        margin: 8px 0;
                    }
                }

            }

            // collpase opened
            .MuiCollapse-entered {
                .MuiCollapse-wrapper {
                    &Inner {
                        .MuiAccordionDetails-root {
                            .radio_label {
                                @include flexbox();
                                @include justify-content(space-between);
                                @include align-items(center);
                                @include flex-wrap(wrap);
                                width: 100%;
                                display: flex;

                                .MuiSvgIcon-root {
                                    width: 2rem !important;
                                    height: 2rem !important;
                                }

                                &:not(:last-child) {
                                    border-bottom: 1px solid var(--grey);
                                    padding: 3px 0;
                                }

                                label {
                                    flex: 0 0 200px;
                                    margin-bottom: 0;

                                    .MuiTypography-root {
                                        @include font-size(1.6);
                                    }
                                }

                                .color_badge {
                                    @media (min-width:1200px) {
                                        min-width: 12px;
                                        max-width: 12px;
                                        min-height: 12px;
                                        max-height: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.events_full_calendar {
    @media (max-width:1199.98px) {
        padding: 0px;
    }

    .rbc-calendar {
        background: var(--white);
        padding: 0px;
        @include border-radius(7px 7px);

        @media (min-width:1200px) {
            height: calc(100vh - 70px - 30px - 30px - 44px - 30px) !important;
        }

        @media (min-width:1400px) {
            height: calc(100vh - 72px - 30px - 44px - 60px) !important;
        }

        @media (min-width:1681px) {
            height: calc(100vh - 88px - 30px - 60px - 50px) !important;
        }

        .rbc-toolbar {
            background: var(--light-blue-100);
            padding: 15px;
            @include border-radius(7px 7px 0 0);
            color: var(--white);
            position: relative;
            margin-bottom: 0;
            border: 1px solid #dddddd;

            @media (max-width:767px) {
                @include flex-flow(column);
            }

            .rbc-btn-group {
                button {
                    background: var(--white);
                    border: 1px solid transparent;
                    margin: 3px;
                    color: var(--base-color);
                    border-radius: 5px;
                    box-shadow: none;
                    @include font-size(1.4);

                    @media (min-width:992px) and (max-width:1366px) {
                        font-size: $f14 - 0.2;
                        margin: 0 3px;
                    }

                    @media (min-width:1200px) and (max-width:1599px) {
                        min-width: 70px;
                    }

                    @media (min-width:1600px) {
                        min-width: 100px;
                    }
                }

                button:hover {
                    background: var(--sky-blue-dark);
                    border-color: var(--sky-blue-dark);
                    color: var(--white);
                }
            }

            .rbc-btn-group:first-child {
                position: $absolute;
                left: 25px;
                top: 50%;
                @include transform(translateY(-50%));

                @media (max-width:767px) {
                    position: $relative;
                    top: unset;
                    left: unset;
                    transform: $none;
                }
            }

            .rbc-btn-group:last-child {
                position: $absolute;
                right: 25px;
                top: 50%;
                @include transform(translateY(-50%));

                @media (max-width:767px) {
                    position: $relative;
                    top: unset;
                    left: unset;
                    right: unset;
                    transform: none;
                }
            }

            &-label {
                background: transparent;
                display: $inline-block;
                width: $auto;
                max-width: 170px;
                @include border-radius(7px 7px);
                padding: 10px;
                font-size: $f20;
                font-weight: $font-bold;
                color: var(--base-color);
                white-space: nowrap;
            }
        }

        .rbc-time-view {
            background: var(--white);
            padding: 10px;
            @include border-radius(0 0 10px 10px);
            border: $none;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);

            .rbc-time-header {
                margin-right: 0;
            }

            .rbc-events-container {
                .rbc-event {
                    border: 1px solid var(--black) !important;
                }
            }
        }
    }
}

.Notes_title {
    text-align: left;
    font-weight: 800 !important;
    text-decoration: underline;
    display: block;
}


.note_description {
    max-height: 220px;
    overflow-y: auto;
}

.delete_radio {
    div[role="radiogroup"] {
        display: flex !important;
        flex-direction: column !important;

        label {
            margin-bottom: 0px;
            width: 100%;
        }
    }
}

// .top_calendar_actions {
//     position: absolute;
//     top: 0px;
//     right: 0px;
// }

.menu-item-events {
    font-size: 1.4rem;
}

.top-left_secn {
    flex: 1;

    @media (max-width:991px) {
        flex: 1;
        width: 100%;
    }

    .article-title {
        @media (min-width:1200px) {
            margin-left: 170px;

        }

        h1 {
            margin-bottom: 0;
        }
    }
}

.top_calendar_actions {
    @media (max-width:575px) {
        flex-flow: column;
        width: 100%;
    }

    @media (min-width:576px) and (max-width:767px) {
        flex-flow: row;
        width: 100%;
    }

    @media (min-width:768px) and (max-width:991px) {
        margin-top: 15px;
        width: 100%;
    }

    .MuiFormControl-root {
        @media (max-width:575px) {
            margin: 10px 0;
        }

        @media (min-width:576px) and (max-width:767px) {
            margin: 10px 10px;
        }

    }

    .btn {
        @media (min-width:1200px) {
            max-height: 42px;
        }

        @media (min-width:768px) and (max-width:1199px) {
            max-height: 42px;
        }
    }

}

.rbc-event-content {
    display: block !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}


// css for dropdown for add tracker
.MuiFormControl-root {
    margin-bottom: 0 !important;

    @media (max-width:575px) {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    div[classnameprefix="add-tracker-dropdown"] {
        transition: all 0.3s ease-in-out;

        .MuiSelect-select {
            padding-top: 0;
            padding-bottom: 0;

            .menu-item-events {
                @media (min-width:1440px) {
                    font-size: $f16;
                    line-height: 1;
                }
            }
        }

        fieldset {
            border: none;
            top: 0;
        }

        &:hover {
            background: var(--lighter-red) !important;
        }
    }
}

.event-menu-item-wrapper {
    .MuiPaper-root {
        .MuiList-root.MuiMenu-list {

            li {
                &.MuiListSubheader-sticky {
                    line-height: 2;
                    border-bottom: 1px solid var(--grey);

                    span {
                        color: var(--sky-blue-dark);
                        font-weight: 500;
                    }
                }

                &.MuiMenuItem-gutters {
                    line-height: 1.1;

                    span {
                        padding-left: 15px;
                        line-height: 1.1;
                        position: relative;
                        // &::before {
                        //     position:absolute;
                        //     left:0;
                        //     top:5px;
                        //     transform: translateY(-50%);
                        //     content: "";
                        //     width: 5px;
                        //     height: 5px;
                        //     border-radius: 10px;
                        //     background-color: red;
                        // }
                    }

                    // &.Mui-disabled {
                    //     span {
                    //         padding-left: 15px;
                    //         line-height: 1.1;
                    //         position: relative;
                    //         &::before {
                    //             display: none;   
                    //         }
                    //     }
                    // }
                }

                span {
                    color: var(--base-color);

                    &.menu-item-events {
                        @media (min-width:1280px) {
                            font-size: $f14;
                        }

                        &-group {
                            @media (min-width:1280px) {
                                font-size: $f16;
                            }
                        }
                    }

                }

            }
        }
    }
}


.hide_date {
    .Mui-selected {
        background-color: #fff;
        color: #000;
    }
}

.event_calendar_time_picker {
    >div {
        border: 1px solid #ccc;
        border-radius: 5px;
    }

    fieldset {
        display: none !important;
    }
}


.print-calendar {
    position: absolute;
    bottom: 200%;
    width: 1120px;
    height: 750px;

    .events_full_calendar {
        width: 100% !important;
        height: 100% !important;

        .rbc-addons-dnd.rbc-calendar {
            width: 100% !important;
            height: 100% !important;
        }
    }
}

.calendar-form-label {
    text-align: left;
    font-weight: bold !important;
    display: inline-block;
}