@import "../../../assets/css/base.scss";

.calendarContainer {     
    @include flexbox;
    @include flex-direction(row);
    gap:20px;
    background: var(--light-blue-100);
    margin-top: 30px;
    padding: 10px;
    @media (max-width:991px) {
        @include flex-direction(column);
        padding: 15px;
        @include justify-content(center);
        @include align-items(center);
    }
    .EventDatePicker {
        flex: 0 0 270px;
        max-width: 270px;
        background: var(--light-blue-100);       
        @include border-radius(7px 0 0 7px);
        @media (max-width:767px) {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
        }
    }
    .EventFullDateDisplay {
        flex: 1 1 calc(100% - 270px - 20px);
        @media (max-width:991px) {
            flex:1;
            width: 100%;
        }
        @media (max-width:767px) {
            flex: 0 0 100%;
            margin-left:0px;
            margin-top: 5px;
        }
    }
}


.calendarAddTrackerDropdown {
    font-style: normal !important;
    background: var(--sky-blue-dark100) !important;
    color: var(--lighter-white) !important;
    @include font-size(1.6 !important);
    @include flexCenter;
    text-transform: $capitalize;
    border: none ;
    min-height: 42px;
    max-height: 42px;
    padding: 10px 4px;
    @include border-radius(12.5px !important);
    outline: $none;
    svg {
        color: $white-color;
        @include font-size(2.1);
        display: $block;
        margin-left: 64px;
    }
    .MuiOutlinedInput-input  {
        background-color: var(--sky-blue-dark100) ;
    }
}


.flexFullRow {
    flex-basis: 100% !important;
    width: 100% !important;
}

.w100Form {
    form {
        display: $block;
        width: 100%;
    }
}


