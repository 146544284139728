@import "../../../assets/css/base.scss";


.frame_row {
    gap: 20px;
    @media (min-width:768px) {
        gap: 30px;
        margin-top: 30px;
    }

    @media (min-width:1440px) {
        gap: 40px;
    }

    @media (max-width:575.98px) {
        column-gap: 15px;
    }

    &_margin {
        @media (min-width:1200px) and (max-width:1440px) {
            margin-top: calc($size-200/2 - 40px);
        }

        @media (min-width:1400px) {
            margin-top: calc($size-200/2);
        }
    }
    &.grid-col-tabs {
        @media (min-width:1200px) and (max-width:1440px) {
            margin-top: calc($size-200/2 - 60px);
        }

        @media (min-width:1440px) {
            margin-top: calc($size-200/2 - 30px);
        }
    }
    // margin css ends for frame row

    &.grid-row {
        @include display-grid;
        grid-template-columns: repeat(2, 1fr);
        justify-items: $center;
        @media (min-width:768px) and (max-width:991px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (min-width:992px) {
            grid-template-columns: repeat(4, 1fr);
        }

        // common grid ends
        &-2 {
            @include display-grid;
            grid-template-columns: repeat(2, 1fr);

            @media (min-width:768px) and (max-width:991px) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media (min-width:992px) {
                grid-template-columns: repeat(4, 1fr);
            }

            @media (min-width:1200px) {
                padding: 0 5%;
            }

            .frame-block {
                // @media (min-width: 1200px) and (max-width:1440px) {
                //     width: calc($size-200 - 10px);                   
                // }

                // @media (min-width: 1440px) and (max-width:1680px) {
                //     width: calc($size-200 - 20px);

                // }

                // @media (min-width: 1680px) {
                //     width: calc($size-200 + 30px);                   
                // }

                // @media (min-width: 768px) and (max-width:1199px) {
                //     width: calc($size-200 - 10px);                  
                // }

                // @media (max-width: 767px) {
                //     width: calc($size-200 - 50px);

                // }
                &_card {
                    @media (min-width: 1200px) and (max-width:1440px) {
                        width: calc($size-200 - 50px);
                        height: calc($size-200 - 50px);
                    }

                    @media (min-width: 1440px) and (max-width:1680px) {
                        width: calc($size-200 - 40px);
                        height: calc($size-200 - 40px);
                    }

                    @media (min-width: 1680px) {
                        width: $size-200;
                        height: $size-200;
                    }

                    @media (min-width: 768px) and (max-width:1199px) {
                        width: calc($size-200 - 10px);
                        height: calc($size-200 - 10px);
                    }

                    @media (max-width: 767px) {
                        width: calc($size-200 - 50px);
                        height: calc($size-200 - 50px);
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: $contain;
                        z-index: 1;

                        @media (min-width: 768px) and (max-width:1199px) {
                            margin-top: -20px;
                        }

                        @media (max-width: 767px) {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }

        // grid row2 ends
        &-3 {
            @include flexbox;
            @include flex-wrap(wrap);
            gap: 20px;

            @media (min-width:768px) and (max-width:991.981199px) {
                gap: 50px;
            }
            @media (min-width:992px) and (max-width:1199.98px) {
                gap: 70px;
            }
            @media (min-width:1200px) and (max-width:1365.98px) {
                gap: 90px;
            }
            @media (min-width:1366px) and (max-width:1440px) {
                gap: 100px;
            }
            @media (min-width:1441px) {
                gap: 150px;
            }

            @media (max-width:575.98px) {
                column-gap: 15px;
            }

            .frame-block {
                @media (min-width: 1200px) {
                    width: calc($size-200 + 20px);
                }

                @media (min-width: 1440px) {
                    width: calc($size-200 + 50px);
                }

                @media (min-width: 1600px) {
                    width: calc($size-200 * 1.25 + 50px);
                }

                @media (min-width: 2500px) {
                    width: calc($size-200 * 2);

                }

                @media (min-width: 768px) and (max-width:1199px) {
                    width: calc($size-200);
                }

                @media (max-width: 767px) {
                    width: calc($size-200 - 50px);

                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: $contain;
                z-index: 1;

                @media (min-width:768px) {
                    width: 110%;
                    height: 110%;
                }
            }

        }
        // grid 2 cols begins
        &-col-2 {
            @include flexbox;
            @include flex-wrap(wrap);
            gap: 20px;

            @media (min-width:768px) and (max-width:991.981199px) {
                gap: 50px;
            }
            @media (min-width:992px) and (max-width:1199.98px) {
                gap: 70px;
            }
            @media (min-width:1200px) and (max-width:1365.98px) {
                gap: 90px;
            }
            @media (min-width:1366px) and (max-width:1440px) {
                gap: 100px;
            }
            @media (min-width:1441px) {
                gap: 150px;
            }

            @media (max-width:575.98px) {
                column-gap: 15px;
            }

            .frame-block {
                @media (min-width: 1200px) {
                    width: calc($size-200 + 20px);
                }

                @media (min-width: 1440px) {
                    width: calc($size-200 + 50px);
                }

                @media (min-width: 1600px) {
                    width: calc($size-200 * 1.25 + 50px);
                }

                @media (min-width: 2500px) {
                    width: calc($size-200 * 2);

                }

                @media (min-width: 768px) and (max-width:1199px) {
                    width: calc($size-200);
                }

                @media (max-width: 767px) {
                    width: calc($size-200 - 50px);

                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: $contain;
                z-index: 1;

                @media (min-width:768px) {
                    width: 110%;
                    height: 110%;
                }
            }

        }
        // dashboard panels
        &-dashboard {
            @include display-grid;
            grid-template-columns: repeat(2, 1fr);

            @media (min-width:768px) and (max-width:991px) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media (min-width:992px) {
                grid-template-columns: repeat(4, 1fr);
            }

            @media (min-width:1200px) {
                padding: 0 5%;
            }
           

            .frame-block {
                // @media (min-width: 1200px) and (max-width:1440px) {
                //     width: calc($size-200 - 50px);                   
                // }

                // @media (min-width: 1440px) and (max-width:1680px) {
                //     width: calc($size-200 - 40px);
                  
                // }

                // @media (min-width: 1680px) {
                //     width: $size-200;
                   
                // }

                // @media (min-width: 768px) and (max-width:1199px) {
                //     width: calc($size-200 - 10px);
                   
                // }

                // @media (max-width: 767px) {
                //     width: calc($size-200 - 50px);
                   
                // }
                
                &_card {
                    @media (min-width: 1200px) and (max-width:1440px) {
                        width: calc($size-200 - 50px);
                        height: calc($size-200 - 50px);
                    }

                    @media (min-width: 1440px) and (max-width:1680px) {
                        width: calc($size-200 - 40px);
                        height: calc($size-200 - 40px);
                    }

                    @media (min-width: 1680px) {
                        width: $size-200;
                        height: $size-200;
                    }

                    @media (min-width: 768px) and (max-width:1199px) {
                        width: calc($size-200 - 10px);
                        height: calc($size-200 - 10px);
                    }

                    @media (max-width: 767px) {
                        width: calc($size-200 - 50px);
                        height: calc($size-200 - 50px);
                    }

                    img {
                        width: 116%;
                        height: 116%;
                        object-fit: $contain;
                        z-index: 1;

                        @media (min-width: 1200px) and (max-width:1440px) {
                            margin-top: -45px;
                        }

                        @media (min-width: 1440px) {
                            margin-top: -60px;
                        }

                        @media (min-width: 768px) and (max-width:1199px) {
                            margin-top: -20px;
                        }

                        @media (max-width: 767px) {
                            margin-top: -40px;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }

        // feeling frame
        // common grid ends
        &-feeling {
            @include display-grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 0;
            row-gap: 15px;

            @media (min-width:768px) and (max-width:991px) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media (min-width:992px) {
                grid-template-columns: repeat(4, 1fr);
            }

            @media (min-width:1366px) {
                padding: 0 5%;
            }

            .frame-block {
                margin: 0 $auto 0px $auto;
                position: $relative;
                @include flexCenter;
                @include flex-direction(column);
                text-align: $center;
                gap: 10px;

                &_card {
                    @include flex-flow(column);
                    gap: 10px;
                    padding: 10px;

                    &::after {
                        display: none;
                    }

                    @media (min-width: 1200px) and (max-width:1440px) {
                        width: calc($size-200 - 50px);
                        height: calc($size-200 - 50px);
                    }

                    @media (min-width: 1440px) {
                        width: calc($size-200 + 30px);
                        height: calc($size-200 + 30px);
                    }

                    // @media (min-width: 1680px) {
                    //     width: $size-200;
                    //     height: $size-200;
                    // }

                    @media (min-width: 768px) and (max-width:1199px) {
                        width: calc($size-200 - 10px);
                        height: calc($size-200 - 10px);
                    }

                    @media (max-width: 767px) {
                        width: calc($size-200 - 50px);
                        height: calc($size-200 - 50px);
                    }

                    img {
                        min-width: calc($size-200/2 - 20px);
                        min-height: calc($size-200/2 - 20px);
                        max-width: calc($size-200/2 - 20px);
                        max-height: calc($size-200/2 - 20px);
                        object-fit: $contain;

                        @media (min-width: 1200px) and (max-width:1440px) {
                            min-width: calc($size-200/2 - 40px);
                            min-height: calc($size-200/2 - 40px);
                            max-width: calc($size-200/2 - 40px);
                            max-height: calc($size-200/2 - 40px);
                        }
                    }
                }
            }
        }
    }
}


.frame-block {
    position: $relative;
    @include flexCenter;
    @include flex-direction(column);
    text-align: $center;
    gap: 10px;

    &_card {
        background-color: $transparent;
        @include border-radius(50%);
        padding: 0;
        @include flexCenter;
        position: $relative;
        transition: background-color 0.5s ease-in-out;
        -webkit-transition: background-color 0.5s ease-in-out;

        @media (min-width: 1200px) {
            width: calc($size-200 + 20px);
            height: calc($size-200 + 20px);
        }

        @media (min-width: 1440px) {
            width: calc($size-200 + 60px);
            height: calc($size-200 + 60px);
        }

        @media (min-width: 1600px) {
            width: calc($size-200 * 1.25 + 30px);
            height: calc($size-200 * 1.25 + 30px);
        }

        @media (min-width: 2500px) {
            width: calc($size-200 * 2);
            height: calc($size-200 * 2);
        }

        @media (min-width: 768px) and (max-width:1199px) {
            width: calc($size-200 - 10px);
            height: calc($size-200 - 10px);
        }

        @media (max-width: 767px) {
            width: calc($size-200 - 50px);
            height: calc($size-200 - 50px);
        }

        @media (max-width:355px) {
            width: calc($size-200 - 60px);
            height: calc($size-200 - 60px);
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: $contain;
            z-index: 1;
        }

        &::before,
        &::after {
            content: '';
            z-index: -1;
            width: 100%;
            height: 100%;
            @include border-radius (50%);
            @include transition (all, 0.6s, ease-in-out);
            @include position($absolute, $top: 0%, $left: 0%, $bottom: 0, $right: 0px);
            transform-origin: center;
        }

        &::before {
            @include opacity (0);
            @include transform(scale(0));
            background-color: rgba($lighter-pink, .4);
            @include transition (all, 0.6s, ease-in-out);
        }

        &::after {
            @include opacity (1);
            @include transform(scale(1));
            background-color: rgba($lighter-pink, 1);
        }

        &:hover {
            &::before {
                @include opacity (1);
                @include transform(scale(1));
                background-color: rgba($lighter-red1, 0.25);
                z-index: 0;
            }
        }
    }

    // title
    .frame-title {
        width: 100%;
        text-align: $center;
        color: var(--base-color);
        font-weight: $font-regular;
        display: $block;
        max-width: 80%;
        margin: 0 $auto ;
        position: $relative;
        line-height: 1.3;
        font-style: normal;
        white-space: normal;
        word-break: normal;
        @media (min-width:576px) {
            max-width: 90%;
        }

        @media (min-width:768px) and (max-width:1440px) {
            @include font-size(1.7);
        }

        @media (max-width:767px) {
            @include font-size(1.5);
        }

        @media (max-width:575.98px) {
            max-width: 90%;
        }

    }
}

