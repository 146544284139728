@import 'base.scss';

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

body,
html {
    height: 100%;
    scrollbar-width: thin;
    scrollbar-color: var(--sky-blue-dark) var(--white);
}

body {
    font-family: $font-Segoe-regular;
    font-size: $f16;
    font-style: normal;
    font-weight: $font-light;
    -webkit-text-stroke-width: 0px;
    overflow-x: $hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scrollbar-width: thin;
    scrollbar-color: var(--sky-blue-dark) var(--white);
    margin: 0;
    padding: 0;
}

aside {
    // font-family:$font-Segoe-regular;
    font-family: $font-Segoue-semilight;
    font-weight: $font-medium;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-Rotunda;   
}

h1,
.h1,
.h2,
.heading_title {
    font-weight: $font-medium;
    letter-spacing: 0.35px;
}

h3,
h4,
h5,
h6 {
    font-weight: $font-regular;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    letter-spacing: 0.35px;
    color: var(--base-color);
    line-height: 1.2;
    word-break: break-word;
}

h1,
.h1 {
    @include font-size(3.6);
    @media (min-width: 1440.11px) {
        @include font-size(3.6);
    }
    @media (min-width: 1900px) {
        @include font-size(4.6);
    }
    @media (max-width: 991px) {
        @include font-size(3);
    }
}

h2,
.h2 {
    @include font-size(3.2);
    @media (min-width:1440.11px) {
        @include font-size(3.6);
    }
    @media (min-width:1900px) {
        @include font-size(4.6);
    }
    @media (max-width: 991px) {
        @include font-size(3);
    }
    @media (max-width: 767px) {
        @include font-size(2.4);
    }
}

h3,
.h3 {
    @include font-size(2);
    @media (min-width: 1440.11px) {
        @include font-size(2.8);
    }
}

h4,
.h4 {
    @include font-size(1.8);
    @media (min-width: 1440.11px) {
        @include font-size(2.2);
    }  
 
}

h5,
.h5 {
    @include font-size(1.7);
    @media (min-width: 1440.11px) {
        @include font-size(2);
    }
}

h6,
.h6 {
    @include font-size(1.6);
    @media (min-width:1440.11px) {
        @include font-size(1.75);
    }
}

a,
a:hover,
a:focus {
    text-decoration: none;
    color: var(--base-color);
    cursor: pointer;
    outline: 0;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

label {
    font-size: $f20 + 0.2;
    margin: 0 0 .5rem;
    color: var(--base-color);
    text-transform: $capitalize;
    @media (max-width:1200px) {
        font-size: $f16;
    }

}
p {
    @include font-size(1.6);
    @media (min-width: 1440.11px) {
        @include font-size(1.7);
    }
}
body {
    .btn {
        cursor: $pointer;
        white-space: nowrap;
        @include transition(all, 0.3s, ease-in-out);
        padding: 0.5rem 1rem;
        @include border-radius(12.5px);
        @include flexCenter;  
        text-align: $center; 
        vertical-align: middle; 
        line-height: 1.5;  
        &[disabled],
        &.disabled {
            @include opacity (0.45);
            color: $base-color;
            pointer-events: $none;
            border-color: transparent;
        }
    
        &:active {
            background-color: $primary-color !important;
        }
        &:focus,
        &:hover,
        &:active {
            box-shadow: $none;
            border-color: $light-pink;
        }
        &:focus-visible {
            @extend .btn-primary;
            box-shadow: none;
        }

        @media (max-width:1199.98px) {
            @include border-radius(10px);
        }
        &-lg {           
            padding: 0.75rem 1.95rem;
            @include font-size(1.75);
            min-width: 180px;
            min-height: 48px;
            @media (max-width:1199.98px) {
                min-height: 42px;
                @include font-size(1.6);
            }
        } 
    
        &-md {
            @include border-radius (12.5px);
            padding: 0.5rem 1.75rem;
            @include font-size(1.75);  
            min-height: 42px;
            min-width: 120px;
            @media (max-width:1199.98px) {
                min-height: 40px;
                @include font-size(1.6);
            }
        }
    
        &-sm {
            min-width: 100px;
            min-height: 40px;
            line-height: 1;
            padding: .55rem 1.2rem;
            @include font-size(1.6);
            font-weight: $font-regular;
            @media (max-width:1199.98px) {

            }
        }
    
        &-xs {
            min-width: 35px;
            min-height: 35px;
            height: 35px;
            width: 35px;
            text-align: $center;
            @include flexCenter();          
            @include border-radius(6px);
            @media (min-width:1440px) {
                min-width: 40px;
                min-height: 40px;
                height: 40px;
                width: 40px;
            }
    
            img {
                max-width: 20px;
            }
            svg {
                max-width: 20px;
            }
    
        }
        &-primary {
            background-color: var(--light-grey);
            color: var(--base-color);
            border-color: var(--light-grey);
    
            &:hover {
                background-color: var(--lighter-red) !important;
                border-color:  var(--lighter-red) !important;
                box-shadow: none;
            }
            &:focus {
                @extend .btn-primary ;
            }
        }
        &-light-blue {
            border: 2px solid $primary-color;
            background-color: rgba($primary-color , 0.0750);
            color: $base-color;    
            &:hover {
                background-color: $light-pink;
                background-color: rgba($light-pink , 0.350) !important;
                border-color: $light-pink !important;
            }
            &:focus {
                @extend .btn-light-blue ;
            }
        }    
        &-light-grey {
            border: 1px solid $light-grey;
            background-color: $light-grey;
            color: $base-color;
            box-shadow: 0px 7px 0px $grey-500;    
            &:hover,
            &:active {
                background-color: $light-pink;
                background-color: $pink-100 !important;
                border-color: $light-pink !important;
                box-shadow: 0px 7px 0px $light-pink !important;
            }    
            &:focus-visible {
                @extend .btn-light-grey;
                box-shadow: 0px 7px 0px $light-pink !important;
                outline: 0;
            }
            &:focus {
                @extend .btn-light-grey ;
            }
        }
        &-green {
            background-color: $green-color;
            border-color: $green-color;
            color: $white-color !important;
            &:hover,
            &:active,
            &:focus {                
                background-color: $green-color-600 !important;
                border-color: $green-color-600 !important;
                color: $white-color;
            }
        }    
        &.sky-blue-btn {
            background-color: var(--sky-blue-dark);
            color: var(--light-white);
            border-color:var(--sky-blue-dark) ;
            &:hover {
                background-color: var(--sky-blue-dark100) !important;
                color: var(--light-white);
                border-color:var(--sky-blue-dark) ;
            }
            &:focus {
                @extend .sky-blue-btn ;
            }
        }
        &-success {
            &:focus {
                @extend .btn-success ;
            }
        }
       
    }     
  
    .btn-outline-primary {
        border: 2px solid var(--base-color);
        color: var(--base-color);
        background: transparent;
    }
    .btn-outline-primary:hover {
        color: var(--base-color) !important;
        background: var(--white);
    }
    
    .dark-blue-btn {
        background: var(--sky-blue-dark100);
        border-color:  var(--sky-blue-dark100);
        color: var(--lighter-white);
        &:hover {
            background-color: var(--lighter-red) !important;
            color: var(--lighter-white);
        }
        &:focus {
            @extend .dark-blue-btn ;
        }
    }
    
    .light-blue-btn {
        background-color: var(--sky-blue-lighter2);
        color: var(--base-color);
        border-color: var(--sky-blue-lighter2);
        text-transform: initial;

        &:hover {
            background-color: var(--lighter-red) !important;
            color: var(--lighter-white);
            border-color: var(--lighter-red);
        }
        &:focus {
            @extend  .light-blue-btn ;
        }
    }
    .light-red-btn {
        background-color: var(--lighter-red1);
        color: var(--base-color);
        border-color: transparent;
        text-transform: initial;
        &:hover {
            border-color: transparent;
            background-color: var(--light-grey) !important;
            color: var(--base-color) !important;
        }
    }
    .done_btn {
        text-transform: capitalize;
    }
    .schedule-meet {
        text-transform: initial;
    }
    .edit-btn {
        background-color: var(--sky-blue3);
        color: var(--base-color);
        border-color: $transparent;
    }
    .sky-blue3-btn {
        background-color: var(--sky-blue3);
        color: var(--base-color);
        border-color: transparent;
        &:hover {
            background-color: var(--lighter-red) !important;
            color: var(--base-color) !important;
            border-color: transparent;
        }
    }
}

.btn.disabled,
.btn:disabled {
    background: #e3e3e3;
    cursor: not-allowed;
}

/* ----common btns for theme--------- */

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

input.form-control:focus,
input.form-control:active,
select.form-select:focus {
    border: none;
    box-shadow: none;
    background-color: var(--light-white);
}

select.form-select {
    min-height: 50px;
    border-radius: 12.5px;
    font-size: calc($f20 + 0.1rem);
    font-weight: $font-light;
    color: var(--base-color);
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 20px;
    background: url(../img/arrrow-icon.png) $no-repeat var(--light-white);
    background-position: calc(100% - 18px);
    background-size: 15px;
    border: none;
}


/** Placeholder **/
::placeholder {
    color: rgba(0, 0, 0, .5);
    opacity: 1;
}
:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(0, 0, 0, .5);
}
::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(0, 0, 0, .5);
}

.form-control::placeholder {
    text-transform: none;
    // font-size:calc($f20 + 0.2rem);
    font-size: calc($f20);
    line-height: $inherit; 
    @media (min-width: 1401px) and (max-width: 1680px) {
        font-size: calc($f16 + 0.1vw);
    }
    @media (min-width: 1681px) {
        font-size: calc($f16 + 0.2vw);
    }
    @media (min-width: 768px) and (max-width: 1440px) {
        font-size: calc($f16 - 0.1vw);
    }
    @media (max-width: 767px) {
        font-size: calc($f16 - 0.1vw);
    }
}

/* scroll */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: $transparent;
    @include border-radius(10px);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--sky-blue-dark);
    @include border-radius(10px);
}
 
button {
    font-weight: $font-light;
}

.form-control {
    background: var(--light-white);
    border: $none;
    @include border-radius (12.5px);
    color: var(--base-color);
    font-size: calc($f20);
    font-weight: $font-light;
    outline: 0 !important;
    @include transition (all, .5s, ease-in-out);
    padding: .75rem 1.6rem;
    line-height: 1.5;
    @media (min-width:1280px) {
        min-height: 44px;
        line-height: 1.5;
    }
    @media (max-width: 1199.98px) {   
        @include border-radius (10px);
    }
    @media (max-width:1280px) {
        min-height: 36px;
    }   
    @media (min-width: 1280px) and (max-width: 1680px) {
        font-size: calc($f20 - 0.3vw);
    }
    @media (min-width: 1200px) and (max-width: 1279px) {
        font-size: calc($f20 - 0.2vw);
    }   
    @media (min-width: 768px) and (max-width: 1199px) {
        font-size: calc($f16 + 0.1vw);
    }     
    @media (max-width: 767px) {      
        font-size: calc($f18 - 0.2vw);
    } 
  
}
textarea {
    &.form-control {
        resize: none;
        &:focus {
            background: var(--light-white);
            box-shadow:none;
        }
    }
}
.form-group {
    margin-bottom: 1.5rem;
    @media (max-width: 1279px) {
        margin-bottom: 1rem;
    }
    @media (min-width: 1280px) and (max-width: 1400px) {
        margin-bottom: 1rem;
    }
    @media (min-width: 1401px) and (max-width: 1680px) {
        margin-bottom: 1.1rem;
    }
}

// header top wrapper
.main_secn {
    @media (min-width: 1680px) {
        padding-left: 250px;
    }
    @media (min-width: 1201px) and (max-width:1440px) {
        padding-left: 200px;
    }
    @media (min-width: 1441px) and (max-width:1680px) {
        padding-left: 210px;
    }
}

// common sidebar right
.sidebar_right {
    width: 100%;
    margin-left: $auto;    
    @media (max-width: 1279px) {
        margin-left: $auto;
    }
    @media (max-width:1200px) {
        width: 100%; 
    }
    .common-section {
        padding: 30px 40px;
        @media (max-width:1200px) {
            padding: 20px 10px;
        }
        @media (min-width: 1201px) and (max-width: 1680px) {
            padding: 25px 30px;
        }       
    }
}
 
.mt-50 {
    margin-top: 30px;
    @media (min-width:1200px) {
        margin-top: 50px;
    }
}

.pointer {
    cursor: $pointer;
}

.w-md-75 {
    @media (min-width:1200px) {
    max-width: 75%;
    }
}

.infinite-scroll-component  {
    scrollbar-width: thin;
    scrollbar-color: var(--sky-blue-dark) var(--white);
}