@import "../../../assets/css/base.scss";

.text_editor .editor_colmn {
    border: 2px solid var(--grey-b9);
    @include border-radius(10px);
    @media (max-width:767px) {
       background-color:var(--white);
    }

   .rdw-editor-toolbar {
        border-radius: 10px 10px 0 0;
        background: var(--light-white);
    }
    .demo-editor{
        padding:10px;
        transition: visibility 0.2s;
        &::-webkit-scrollbar {
            visibility: hidden;
            display: none;
        }
        &:hover, &:focus {
            &::-webkit-scrollbar {
                visibility: visible;
                display: block;
            }
        }
    }

    ul li {
        max-width: unset !important; 
        justify-content:unset !important; 
        align-items:unset !important; 
        margin-bottom: unset !important;
        cursor: pointer !important; 
        text-align:unset !important;
    }

    .rdw-dropdown-optionwrapper {
        overflow-y: hidden !important;
    }

    .rdw-list-dropdown {
        z-index: 0;
    }
}

.override_jodit {
    .jodit-react-container {
        .jodit-wysiwyg {
            strong {
                font-weight: bold;
            }
        
            ul {
                margin: unset;
                padding: 20px;
                list-style-type: disc; 
                li {
                    flex: unset;
                    max-width: unset;
                    display: list-item;
                    justify-content: unset;
                    align-items: unset;
                    margin-bottom: unset;
                    cursor: unset;
                    text-align: unset;
                }
            }
        }
    }   
}

// .quesns_list .override_jodit {
//     border-bottom: 0.5px solid #ddd;
// }