@import "../../../assets/css/base.scss";

.seekbarClass {
  position: relative;
  top: -74px;
  z-index: 1;
}


.seekbarMobileClass {
  position: relative;
  z-index: 1;
}
.playerControls {
  position: relative;
  top: -78px;
  display: flex;
  justify-content: space-between;
  background: #000000d1;
  padding: 5px;
  align-items: center;
}

.videoTimer {
  color: rgb(255,255,255) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.33rem;
  margin-left:5px;
  padding-left: 20px;
  padding-right: 20px;
}


.nextBtn{
  margin-left: 10px;
  margin-right: 10px;
  border: none;
    background: transparent;
    color: white;
    padding: 0px 0px;
}

.materialButton {
  color: #fff !important;
  font-size: 2.5rem !important;
  border:none !important;
  padding: 0;
  &:focus,&:active,&:focus-visible {
    background-color: transparent !important;
    border: none !important;
  }
  svg {
    width: 25px;
    height: 25px;
  }
}
.video_node_secn {
  .materialButton {  
    &:focus,&:active,&:focus-visible {
      background-color: transparent !important;
      border: none !important;
    }
  }
}

.playspeed_btns {
  position: relative;
}
.playspeed_btns label {
    cursor: pointer;
  }
  .speedList {
      position: $absolute;
      top: -286px;
      right: 0;
      min-width: 200px;
      display: flex;
      flex-flow: column;
      justify-content: space-around;
      align-items: center;
      background: #000000d1;
      padding: 10px;
      z-index: 1;
      @include border-radius(6px);
      @media (max-width:767px) {
        top:30px;
      }
  }
  .speedList button {
      background: transparent;
      border: none;
      color: #fff;
      padding: 3px 0;
      border-bottom: 1px solid #5f5f5f;
      width: 100%;
      &:last-child {
        border: none;
      }
  }

.rotateSettingbuttonRight {
  transform: rotate(45deg)
}

.rotateSettingbuttonLeft {
  transform: rotate(0deg)
}

.videoEl {
  cursor: pointer;
  display: none;
}

/* Desktop size */
@media (min-width: 768px) {

  .videoEl {
    /* max-width: 100%; */
    width: 500;
    height: 500;
  }
}

/* Mobile devices - excluding ipad*/
@media (max-width: 767px) {

  .videoEl {
    max-width: 100%;
    background-color: inherit;
  }
}

.speedActive {
  color: #48a2e3 !important;
}

.btnSvgDark {
  svg {
    fill: #000;
  }
}